import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AddQuestions } from './AddQuestions'
import { ShowQuestions } from './ShowQuestions'
import { ImageEdit } from "./ImageEdit";
import {
  CollectionType,
  UpdateCollectionType,
  LanguagesInputs,
  Language,
  CollectionTranslation,
  Group,
  DynamicField,
  KeyBoolean
} from '../../types'
import { getCollectionKeyInLangWithDefault } from '../../helpers/helperFunctions'
import { toastNotifySuccess, toastNotifyError } from '../common/ToastMessage'
import { AdminApi } from "../../api/admin";
import { CustomCheckbox } from '../common/CustomCheckbox'
import { CollectionTranslations } from './CollectionTranslations'
import { DynamicFieldsPicker, SelectedField, PickerOptionType, getFieldInLang } from './DynamicFieldsPicker'
import { CloseCollection } from './CloseClollection'
import { ConfirmationDialog } from '../common/ConfirmationDialog'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'


type EditCollectionProps = {
  collection: CollectionType | null
  organizationId: string
  organizationColor: string
  updateState: Function
  updateSuccess: Function
  collectionDeleted: Function
}

type CollectionFieldsType = {
  isPublic: boolean
  groupName: string
  allowRelatedFiles: boolean
  questions: string[]
  hasTerms: boolean
  status: string
  archivedText: string
}


export const Edit = ({ collection, organizationColor, updateState, updateSuccess, organizationId, collectionDeleted }: EditCollectionProps) => {
  const { i18n } = useTranslation()
  const { getTranslation } = useCustomTranslation(organizationId)
  const [receviedNewCollection, setReceivedNewCollection] = useState<boolean>(false)
  const [apiLanguages, setApiLanguages] = useState<Language[] | null>(null)
  const [isSendingUpdate, setIsSendingUpdate] = useState<boolean>(false)
  const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false)
  const [contributionFields, setContributionFields] = useState<DynamicField[] | null>(null)
  const [selectedContributionFields, setSelectedContributionsFields] = useState<SelectedField[]>([])
  const [collectionForm, setCollectionForm] = useState<CollectionFieldsType>({
    isPublic: true,
    groupName: "",
    allowRelatedFiles: true,
    questions: [],
    hasTerms: false,
    status: '',
    archivedText: ''
  })
  const [languages, setLanguages] = useState<LanguagesInputs | null>(null)
  const [groups, setGroups] = useState<(Group[])>([])
  const [statusList, setStatusList] = useState<string[]>([])

  const areTranslationsEqual = useCallback((translations: CollectionTranslation[]) => {
    if (!languages) return true
    let isEqual = true
    for (const [key, value] of Object.entries(languages)) {
      let existingTranslations = translations.filter(t => t.languageCode === key)
      if (existingTranslations.length === 0 && (value.title.length !== 0 || value.description.length !== 0)) {
        isEqual = false
        continue
      }
      if (existingTranslations.length > 0) {
        let t = existingTranslations[0]
        isEqual = isEqual && (t.description === value.description && t.title === value.title)
      }
    }
    return isEqual
  }, [languages]);


  const verifyIfFormChange = useCallback(() => {
    if (!collection || receviedNewCollection) {
      setHasBeenChanged(false)
      return
    }
    let isEqual = true
    for (const [key, value] of Object.entries(collectionForm)) {
      isEqual = isEqual && (key === 'isPublic' && collectionForm.isPublic !== collection.isPublic)
      isEqual = isEqual && (key === 'groupName' && collectionForm.groupName !== collection.groupName)

      if (key === 'questions') {
        if ((!collection.questions || collection.questions.length === 0) && (!collectionForm.questions || collectionForm.questions.length === 0)) {
          isEqual = isEqual && true
          continue
        }
        if ((collection.questions && collection.questions.length > 0) && (collectionForm.questions && collectionForm.questions.length > 0)) {
          let questionsEqual = (JSON.stringify(collection.questions.slice().sort()) === JSON.stringify(collectionForm.questions.slice().sort()))
          isEqual = isEqual && questionsEqual
          continue
        }
        isEqual = isEqual && false
      }
      isEqual = isEqual && (key in collection && value === collection[key as keyof CollectionType])
    }

    isEqual = isEqual && collection.isPublic === collectionForm.isPublic
    isEqual = isEqual && areTranslationsEqual(collection.translations)
    isEqual = isEqual && collection.status === collectionForm.status
    setHasBeenChanged(!isEqual)
  }, [areTranslationsEqual, collection, collectionForm, receviedNewCollection]);


  const handleTranslationUpdate = (language: string, key: 'title' | 'description', value: string) => {
    if (languages && language in languages) {
      let prevLangValues = languages[language]
      prevLangValues[key] = value
      setLanguages(prev => ({ ...prev, [language]: prevLangValues }))
      setReceivedNewCollection(false)
      verifyIfFormChange()
    }

  }

  const updateQuestions = (question: string) => {
    const prevQuestions = collectionForm.questions ? [...collectionForm.questions, question] : [question]
    setReceivedNewCollection(false)
    setCollectionForm(prev => ({ ...prev, questions: prevQuestions }))
  }

  const deleteQuestion = (question: string) => {
    let filtered = (collectionForm.questions.filter(q => q.toLowerCase() !== question.toLowerCase())) as string[]
    setReceivedNewCollection(false)
    setCollectionForm(prev => ({ ...prev, questions: filtered }))
  }



  const cancelChanges = () => {
    if (collection) {
      setCollectionForm({
        allowRelatedFiles: collection.allowRelatedFiles,
        isPublic: collection.isPublic,
        groupName: collection.groupName,
        questions: collection.questions,
        hasTerms: collection.hasTerms,
        status: collection.status,
        archivedText: collection.archivedText ? collection.archivedText : ''
      })
      setHasBeenChanged(false)
    }
  }

  const termsFieldIsConsideredEmpty = (terms: string) => {
    let isEmpty = false
    switch (terms) {
      case '':
      case '<h1><br></h1>':
      case '<h2><br></h2>':
      case '<h3><br></h3>':
      case '<p><br></p>':
        isEmpty = true
        break;
      default:
        isEmpty = false
    }
    return isEmpty
  }

  const validateForm = () => {
    if (!languages) return false
    let entries = Object.entries(languages)
    let isValid = entries.reduce((acc, current) => {
      let needsTerms = collectionForm.hasTerms ? current[1].terms != null && !termsFieldIsConsideredEmpty(current[1].terms) : true
      if (current[1].required) {

        return acc && (current[1].title.length > 0 && current[1].description.length > 0 && needsTerms)
      } else if (current[1].title !== '' || current[1].description !== '') {
        return acc && needsTerms
      }
      return acc && true
    }, true)
    return isValid
  }


  const submissionFormatTranslations = () => {
    if (!languages) return ""
    let formatted: any = []
    for (const [key, value] of Object.entries(languages)) {
      if (value.title === '' || value.description === '') {
        continue
      }

      let langObject: CollectionTranslation = {
        languageCode: key,
        title: value.title,
        description: value.description,
      }

      if (collectionForm.hasTerms) {
        langObject.terms = value.terms
      }
      formatted.push(langObject)
    }
    return formatted
  }

  const formatContributionFields = () => {
    let cfields: KeyBoolean = {}
    selectedContributionFields.forEach((f: SelectedField) => {
      const entries = Object.entries(f)
      let fieldId = entries[0][1]
      cfields[fieldId as string] = entries[2][1] as boolean
    })
    return cfields
  }

  const handleSubmit = async () => {
    if (!validateForm() || !collection || !languages) return
    if (collectionForm.status === 'ARCHIVED' && collectionForm.archivedText === '') {
      toastNotifyError(('ADMIN.EDIT_COLLECTION.MISSING_FIELDS_ERROR'))
      return
    }
    let formattedContributionFields = formatContributionFields()
    let formatted = submissionFormatTranslations()
    let data: UpdateCollectionType = {
      collectionTranslations: formatted,
      collectionId: collection.id,
      isPublic: collectionForm.isPublic,
      groupName: collectionForm.groupName,
      allowRelatedFiles: collectionForm.allowRelatedFiles,
      hasTerms: collectionForm.hasTerms,
      contributionFields: formattedContributionFields,
      status: collectionForm.status
    }

    if (collectionForm.questions && collectionForm.questions.length > 0) {
      data.questions = collectionForm.questions
    }
    if (collectionForm.status === 'ARCHIVED') {
      data.archivedText = collectionForm.archivedText
    }

    setIsSendingUpdate(true)
    try {
      const response = await AdminApi.updateCollection(data)
      toastNotifySuccess(getTranslation('GLOBAL.UPDATE_SUCCESS'))
      updateSuccess(response.data)

    } catch (error: any) {
      toastNotifyError(error.response ? error.response.data.message : 'Something went wrong, could not update collection')

    } finally {
      setIsSendingUpdate(false)
    }
  }

  useEffect(() => {
    const setUpLanguages = (collection: CollectionType, fetchedLanguages: Language[]) => {
      let x: LanguagesInputs = {}
      fetchedLanguages.forEach((l: Language) => {
        x[l.language] = {
          required: l.required,
          language: l.language,
          title: getCollectionKeyInLangWithDefault(collection, 'title', l.language),
          description: getCollectionKeyInLangWithDefault(collection, 'description', l.language),
          terms: getCollectionKeyInLangWithDefault(collection, 'terms', l.language)
        }
      });
      setLanguages(x)

    }
    const getLanguages = async (collection: CollectionType) => {
      const response = await AdminApi.getLanguages()
      setApiLanguages(response)
      setUpLanguages(collection, response)
    }

    const getGroups = async (orgId: string) => {
      try {
        const response = await AdminApi.getGroups(orgId)
        setGroups(response.data)
      } catch (error: any) {
        console.error(error)
      }
    }

    const getAvaialbleContributionFields = async (organizationId: string, collection: CollectionType) => {
      const response = await AdminApi.getContributionFields(organizationId)
      setContributionFields(response)
      let keyValues = Object.entries(collection.contributionFields)
      const collectionContributionFields = keyValues.map((value: [string, boolean]) => {
        let f: DynamicField[] = response.filter((elem: DynamicField) => elem.field === value[0])
        return {
          id: f[0].field,
          name: getFieldInLang(f[0], i18n.language),
          required: collection.contributionFields[f[0].field]
        }
      })
      setSelectedContributionsFields(collectionContributionFields)
    }

    const getCollectionStatusList = async (organizationId: string) => {
      try {
        const response = await AdminApi.getCollectionStatusList(organizationId)
        setStatusList(response)
      } catch (error) {

      }
    }

    if (collection) {
      setHasBeenChanged(false)
      setReceivedNewCollection(true)
      getGroups(collection.organizationId)

      setCollectionForm({
        allowRelatedFiles: collection.allowRelatedFiles,
        isPublic: collection.isPublic,
        groupName: collection.groupName,
        questions: collection.questions,
        hasTerms: collection.hasTerms,
        status: collection.status,
        archivedText: collection.archivedText ? collection.archivedText : ''
      })

      if (!statusList.length) {
        getCollectionStatusList(organizationId)
      }
      if (!apiLanguages) {
        getLanguages(collection)
      } else {
        setUpLanguages(collection, apiLanguages)
      }

      getAvaialbleContributionFields(organizationId, collection)

    }
  }, [collection, apiLanguages, organizationId, i18n, statusList])

  useEffect(() => {
    verifyIfFormChange()
  }, [collectionForm.allowRelatedFiles, collectionForm.isPublic, collectionForm.questions, collectionForm.status, collectionForm.archivedText, verifyIfFormChange])

  useEffect(() => {
    updateState(hasBeenChanged)
  }, [hasBeenChanged, updateState])

  if (!collection || !languages) {
    return null
  }

  const getBadgeClasses = (status: string) => {
    let classes = "collection-status-badge "

    switch (status) {
      case 'ACTIVE':
        classes = classes + "collection-status-active"
        break
      case 'CLOSED':
        classes = classes + "collection-status-closed"
        break
      case 'ARCHIVED':
        classes = classes + "collection-status-archived"
        break;
      case 'DELETE':
        classes = classes + "collection-status-delete"
        break
      default:
        classes = classes + "collection-status-default"
    }

    if (status === collection.status) {
      classes = classes + " collection-current-status"
    }

    return classes
  }


  const possibleNextStatus = () => {

    if (collectionForm.status === 'ACTIVE') {
      return statusList.filter((status: string) => status === 'CLOSED')
    }
    if (collectionForm.status === 'CLOSED') {
      if (collection.status === 'CLOSED') {
        return statusList.filter((status: string) => status === 'ARCHIVED' || status === 'ACTIVE')
      }
      if (collection.status === 'ARCHIVED') {
        return statusList.filter((status: string) => status === 'ARCHIVED')
      }

      return statusList.filter((status: string) => status === 'ACTIVE')
    }


    if (collectionForm.status === 'ARCHIVED') {
      let statuses = statusList.filter((status: string) => status === 'CLOSED')
      return statuses
    }

    return []
  }

  const deleteCollection = async () => {
    try {
      await AdminApi.deleteCollection(collection.organizationId, collection.id)
      toastNotifySuccess(getTranslation('ADMIN.EDIT_COLLECTION.DELETE_COLLECTION.DELETE_SUCCESS'))
      collectionDeleted(collection.id)

    } catch (error: any) {
      toastNotifyError(error.response ? error.response.data.message : 'Something went wrong, could not delete collection')
    }
  }
  const submitButtonClasses = isSendingUpdate ? 'loader-btn loader-btn--loading btn-stnd-al m-t-50' : 'loader-btn btn-stnd-al m-t-50'

  return (
    <div className="create-collection-container">
      <ImageEdit
        translationFunction={getTranslation}
        entityId={collection.id}
        currentImageUrl={collection.imgUrl}
        buttonColor={organizationColor}
        updateFunction={AdminApi.updateCollectionImage}
        updateText={getTranslation('ADMIN.EDIT_COLLECTION.EDIT_IMG_BUTTON')} />

      <div className="collection-group">
        <div className="collection-status-wrapper">
          <div className="collection-status-div">
            <span>{getTranslation('ADMIN.EDIT_COLLECTION.COLLECTION_STATUS')}</span><span className={getBadgeClasses(collectionForm.status)}>{collectionForm.status}</span>
          </div>
          <div className="collection-status-div">
            <span>{getTranslation('ADMIN.EDIT_COLLECTION.POSSIBLE_STATUSES')}</span>
            {possibleNextStatus().map((status: string) => {
              return (
                <span className={getBadgeClasses(status)} onClick={() => {
                  setCollectionForm(prev => ({ ...prev, status }))
                  setReceivedNewCollection(false)
                }}>{status}</span>
              )
            })}
          </div>
          {(collectionForm.status === 'ARCHIVED' || collection.status === 'ARCHIVED') && (
            <CloseCollection
              translationsFunction={getTranslation}
              updateCloseText={(updatedText: string) => {
                setReceivedNewCollection(false)
                setCollectionForm(prev => ({ ...prev, archivedText: updatedText }))
              }}
              initialValue={collection.archivedText || ''}
              disableEditor={collection.status === 'ARCHIVED' && collectionForm.status === 'CLOSED'}
            />
          )}
          {collection.status === 'ARCHIVED' &&
            (<ConfirmationDialog
              translationFunction={getTranslation}
              title={getTranslation('ADMIN.EDIT_COLLECTION.DELETE_COLLECTION.TITLE')}
              subTitle={getTranslation('ADMIN.EDIT_COLLECTION.DELETE_COLLECTION.SUB_TITLE')}
              buttonConfirmText={getTranslation('ADMIN.EDIT_COLLECTION.DELETE_COLLECTION.DELETE_TRIGGER')}
              checkboxText={getTranslation('ADMIN.EDIT_COLLECTION.DELETE_COLLECTION.DELETE_CHECKBOX_TEXT')}
              triggerButtonText={getTranslation('ADMIN.EDIT_COLLECTION.DELETE_COLLECTION.DELETE_TRIGGER')}
              onConfirmClick={deleteCollection}
              danger
            />)}

        </div>

        <CustomCheckbox
          text={getTranslation('ADMIN.FIELD_IS_PUBLIC')}
          value={collectionForm.isPublic}
          onUpdate={() => {
            setHasBeenChanged(true)
            setReceivedNewCollection(false)
            let currentValue = collectionForm.isPublic
            setCollectionForm(prev => ({ ...prev, isPublic: !currentValue }))
          }}
        />
        <CustomCheckbox
          text={getTranslation('ADMIN.FIELD_HAS_TERMS')}
          value={collectionForm.hasTerms}
          onUpdate={() => {
            setHasBeenChanged(true)
            setReceivedNewCollection(false)
            let currentValue = collectionForm.hasTerms
            setCollectionForm(prev => ({ ...prev, hasTerms: !currentValue }))
          }}
        />
        {!collectionForm.isPublic ?
          <select id="groups" value={collectionForm.groupName} size={1} onChange={(e) => {
            setHasBeenChanged(true)
            let { value } = e.target;
            setCollectionForm(prev => ({ ...prev, groupName: value }))
          }} disabled={!groups || groups.length === 0}>
            {groups.length > 0 ? <option value='default' hidden> -- {getTranslation('GROUPS.SELECT_GROUP')} -- </option>
              : <option value='_no_group'> -- {getTranslation('GROUPS.NO_GROUPS')} -- </option>}
            <option value='_no_group'> -- {getTranslation('GROUPS.NO_GROUP')} -- </option>
            {groups.map((group, index) => {
              return (<option key={group.name} value={group.name} >{group?.name}</option>)
            })}
          </select> : ""}
      </div>

      <CustomCheckbox
        text={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.ALLOW_RELATED_FILES')}
        value={collectionForm.allowRelatedFiles}
        onUpdate={() => {
          setReceivedNewCollection(false)
          let currentValue = collectionForm.allowRelatedFiles
          setCollectionForm(prev => ({ ...prev, allowRelatedFiles: !currentValue }))
        }}
      />
      <DynamicFieldsPicker
        title={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.CONTRIBUTION_MEDIA_FIELDS')}
        fields={contributionFields || []}
        removeField={(fieldId: string) => {
          let filtered = selectedContributionFields.filter(f => f.id !== fieldId)
          setSelectedContributionsFields(filtered)
        }}
        onUpdate={(option: PickerOptionType) => {
          let exists = selectedContributionFields.filter(field => field.name === option.name)
          if (exists.length > 0) {
            return
          }
          let n = {
            id: option.id,
            name: option.name,
            required: false
          }
          setSelectedContributionsFields(prev => ([...prev, n]))
          setHasBeenChanged(true)
        }}
        currentFields={selectedContributionFields}
      />
      <div style={{ padding: '10px', width: '300px' }}>
        {selectedContributionFields.map((f: SelectedField) => {
          return (
            <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr 1fr' }}>
              <div style={{ marginRight: '10px' }}>{f.name}</div>
              <CustomCheckbox
                text={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.REQUIRED_FIELD')}
                value={f.required}
                onUpdate={() => {
                  f.required = !f.required
                  setHasBeenChanged(true)
                  setSelectedContributionsFields([...selectedContributionFields])
                }}
              />
            </div>
          )
        })}
      </div>
      <CollectionTranslations
        translationsFunction={getTranslation}
        languages={languages}
        onTranslationUpdate={handleTranslationUpdate}
        hasTerms={collectionForm.hasTerms} />
      <AddQuestions
        title={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.CREATE_QUESTION_TITLE')}
        addQuestion={updateQuestions}
        buttonColor={organizationColor}
        addButtonText={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.CREATE_QUESTION_ADD_BUTTON')}
      />
      <ShowQuestions
        title={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.ADDED_QUESTIONS')}
        questions={collectionForm.questions}
        deleteQuestion={deleteQuestion}
        buttonColor={organizationColor}
      />
      <button className="btn" style={{ backgroundColor: organizationColor }} onClick={cancelChanges}>{getTranslation('GLOBAL.CANCEL')}</button>
      <button className={submitButtonClasses} style={{ backgroundColor: `${organizationColor}` }} onClick={handleSubmit} disabled={!hasBeenChanged || !validateForm()}>
        <span className="loader-btn__text">{getTranslation('ADMIN.EDIT_COLLECTION.SUBMIT_EDIT')}</span>
      </button>
    </div >
  )

}