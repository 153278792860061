import * as Accordion from '@radix-ui/react-accordion';
import { HiOutlineChevronDown } from 'react-icons/hi'
import { BsExclamationCircle } from 'react-icons/bs'
import { LanguagesInputs } from '../../types'
import { Text } from '../CreateContribution/formComponents/FormComponents'
import { TextEditor } from '../common/TextEditor'
import { preventDefault } from '../../helpers/helperFunctions'

type Props = {
  languages: LanguagesInputs
  onTranslationUpdate: Function
  hasTerms: boolean
  translationsFunction: Function
}

export const CollectionTranslations = ({ languages, onTranslationUpdate, hasTerms, translationsFunction }: Props) => {
  const handleFormUpdate = (lang: string, key: string, value: string) => {
    onTranslationUpdate(lang, key, value)
  }

  const translationSection = () => {
    let entries = Object.entries(languages)
    return entries.map(l => {
      return (
        <Accordion.Item value={l[0]} className="create-collection-accordion-item ">
          <Accordion.Header className="create-collection-accordion-header">
            {l[1].required ? (
              <div>
                {l[0]}
                <BsExclamationCircle style={{ marginLeft: '10px' }} />
              </div>
            ) : l[0]}
            <Accordion.Trigger className="create-collection-accordion-trigger" >
              <HiOutlineChevronDown className="create-collection-accordion-trigger-icon" />
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="create-collection-accordion-content">
            <form onSubmit={e => {
              e.preventDefault()
            }} name="createCollectionForm">
              <fieldset>
                <Text
                  title={translationsFunction('ADMIN.FIELD_TITLE')}
                  name="title" placeholder=""
                  onUpdate={(name: string, newValue: string) => handleFormUpdate(l[0], name, newValue)}
                  handleKeyDown={preventDefault}
                  value={l[1].title} />
                <div className="field-pair">
                  <label htmlFor={"description"}>{translationsFunction('ADMIN.FIELD_DESCRIPTION')}</label>
                  <TextEditor
                    simple
                    initialValue={l[1].description}
                    onChange={(newValue: string) => { handleFormUpdate(l[0], "description", newValue) }}
                  />
                </div>
                {
                  hasTerms && (
                    <div className="field-pair">
                      <label htmlFor={"terms"}>{translationsFunction('ADMIN.FIELD_TERMS')}</label>
                      <TextEditor
                        initialValue={l[1].terms}
                        onChange={(newValue: string) => { handleFormUpdate(l[0], "terms", newValue) }}
                      />
                    </div>
                  )
                }
              </fieldset >
            </form >
          </Accordion.Content >
        </Accordion.Item >
      )
    })
  }

  return (
    <div className="create-collection-translations-section">
      <h2>{translationsFunction('ADMIN.TRANSLATIONS_TITLE')}</h2>
      <Accordion.Root type="multiple" className="create-collection-accordion-root">
        {translationSection()}
      </Accordion.Root>
    </div>
  )

}
