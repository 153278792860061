import { createContext, useContext, useState, useEffect } from 'react'
import { OrganizationType, HelpPageTranslation, OrganizationAppTranslations } from '../types'
import { Api } from '../api/api'
import { addTranslation } from '../translations/i18n'

type OrganizationContextType = {
  organization: OrganizationType | null
  addOrganizationToContext: Function
  unsetOrganization: Function
  helpPageTranslations: HelpPageTranslation[]
  updateHelpPageTranslations: Function
  updateOrganizationAppTranslations: Function
}

const OrganizationContext = createContext<OrganizationContextType | undefined>(undefined)


const OrganizationProvider = ({ children }: any) => {
  const [organization, setOrganization] = useState<OrganizationType | null>(null)
  const [helpPageTranslations, setHelpPageTranslations] = useState<HelpPageTranslation[]>([])
  const [fecthedOnce, setFetchedOnce] = useState<boolean>(false)


  const addOrganizationToContext = (org: OrganizationType) => {
    setOrganization(org)
    // Add organization app translations to i18Next
    if (org.appTranslations && org.appTranslations.length) {
      org.appTranslations.forEach((translation: OrganizationAppTranslations) => {
        let t = JSON.parse(JSON.parse(translation.translation))
        addTranslation(t, translation.organizationId, translation.languageCode)
      })
    }
  }

  const unsetOrganization = () => {
    setOrganization(null)
  }

  const updateHelpPageTranslations = (translations: HelpPageTranslation[]) => {
    setHelpPageTranslations(translations)
  }

  const updateOrganizationAppTranslations = (newTranslations: OrganizationAppTranslations) => {
    if (!organization) return
    let t = JSON.parse(JSON.parse(newTranslations.translation))
    addTranslation(t, newTranslations.organizationId, newTranslations.languageCode)
    if (organization) {

      let prevAppTranslations = organization.appTranslations
      prevAppTranslations.forEach((t: OrganizationAppTranslations) => {
        if (t.languageCode === newTranslations.languageCode) {
          t.translation = newTranslations.translation
        }
      })
      let o = organization
      setOrganization({ ...o, appTranslations: prevAppTranslations })
    }
  }

  useEffect(() => {
    const fetchTranslations = async (organizationId: string) => {
      try {
        const response = await Api.getOrganizationHelpPages(organizationId)
        setHelpPageTranslations(response)
      } catch (error: any) {
        console.error("Could not get user due to: ", error)
      }
    }
    if (!helpPageTranslations.length && organization && !fecthedOnce) {
      fetchTranslations(organization.id)
      setFetchedOnce(true)
    }
  }, [helpPageTranslations, organization, fecthedOnce])

  return (
    <OrganizationContext.Provider
      value={{ organization, addOrganizationToContext, unsetOrganization, updateHelpPageTranslations, helpPageTranslations, updateOrganizationAppTranslations }} >
      {children}
    </OrganizationContext.Provider >
  )
}

const useOrganizationContext = (): OrganizationContextType | undefined => useContext(OrganizationContext)
export { OrganizationProvider, useOrganizationContext, OrganizationContext }