import '../../css/Profile.css';

type Props = {
  header: string
  subtitle: string
  fields: any

}

export const ProfileDetailsList = ({ subtitle, header, fields }: Props) => {
  return (
    <div className="profile-card">
      <div className="profile-card-header">
      </div>
      <div className="profile-details">

        {Object.keys(fields).map((value: string, index: number, array: string[]) => {
          return (<div key={index} className={"profile-field"} id={value}>
            <p className="profile-key">{value}</p>
            <p className="profile-value">{fields[value] ? fields[value] : "-"}</p>
          </div>
          )
        })}
      </div>
    </div >
  );
}



