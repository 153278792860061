import "../../css/Gallery.css";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useOrganizationContext } from "../../context/OrganizationContext";

import { ImageType } from "../../types";
import { ROUTE } from "../../routes";
import { Api } from "../../api/api";
import { isImage } from "../../helpers/mediaFileFunctions";
import { useCustomTranslation } from "../../helpers/CustomTranslationsComps";
import { CgProfile } from "react-icons/cg";
import { AiFillCamera, AiOutlineClose, AiOutlineHeart } from "react-icons/ai";
import { BsArrowReturnLeft, BsBackspace, BsFillBookmarkFill } from "react-icons/bs";

/*TODO 
1. animera först framen
2. ta bort gamla framed
3. buffra nya frames, innan animeras
*/

export type GalleryImage = {
  id: string;
  imageUrl: string;
  title: string | null;
  description: string | null;
  date: Date | null;
  location: string | null;
  socials: Array<string> | null;
  tags: Array<string> | null;
  isPhotographer: boolean | null;
  license: string | null;
  otherPhotographer: string | null;
  openDate: string | null;
  paginationId: number;
  contributionUserFirstName: string;
  contributionUserLastName: string;
  contributionUserFullName: string;
  show: boolean;
  // thumbnailUrl: string | null;
  // metaData: string | null;
  // originalFileName: string;
  // contributionId: string;
  locationLat: number | null;
  locationLng: number | null;
};

type OldGalleryImage = {
  imageUrl: string;
  paginationId: number;
  id: string;
  show: boolean;
};

type Frame = {
  frameId: number;
  isFirstFrame: boolean;
  lastElementRef: React.MutableRefObject<HTMLDivElement | null>;
  images: GalleryImage[];
  nextPaginationId: number;
};

export const Gallery = () => {
  let history = useHistory();
  const location = useLocation();
  const handle = useFullScreenHandle();
  const orgCtx = useOrganizationContext();
  const appCtx = useApplicationContext();

  const { collectionId, orgId }: any = useParams();
  const organizationId = orgId;

  const [isPaused, setIsPaused] = useState(false);
  const [selectedImage, setSelectedImage] = useState<GalleryImage | null>(null);
  const [collectionTitle, setCollectionTitle] = useState("");

  const [frames, setFrames] = useState<Frame[]>([]);
  const [lastFrame, setLastFrame] = useState<Frame | null>(null);
  const [frameCount, setFrameCount] = useState<number>(0);
  const { getTranslation } = useCustomTranslation(organizationId || "");

  const fetchNextFrame = async (restart: boolean, firstFrame: boolean) => {
    if (!collectionId) return;

    let paginationId = 0;
    if (lastFrame && !restart) {
      paginationId = lastFrame.nextPaginationId;
    }

    const response = await Api.getCollectionImages(collectionId, paginationId, true);

    let newImages = response.filter((media: ImageType) => isImage(media.originalFileName));
    console.log({ response });
    let frameImages: GalleryImage[] = [];
    for (let i = 0; i < newImages.length; i++) {
      let image = newImages[i];
      let fullname = image.contributionUserFirstName + " " + image.contributionUserLastName;
      if (image.thumbnailUrl && image.thumbnailUrl !== "") {
        let galleryImage: GalleryImage = {
          imageUrl: image.thumbnailUrl,
          paginationId: image.paginationId,
          id: image.id,
          date: image.date,
          openDate: image.openDate,
          location: image.location,
          description: image.description,
          license: image.license,
          isPhotographer: image.isPhotographer,
          otherPhotographer: image.otherPhotographer,
          show: true,
          socials: image.socials,
          tags: image.tags,
          title: image.title,
          contributionUserFirstName: image.contributionUserFirstName,
          contributionUserLastName: image.contributionUserLastName,
          contributionUserFullName: fullname,
          locationLat: image.locationLat,
          locationLng: image.locationLng,
        };
        frameImages.push(galleryImage);
      }
    }

    if (frameImages.length === 0) {
      fetchNextFrame(true, false);
      return;
    }

    let lastImage = frameImages[frameImages.length - 1];

    let newFrameId: number = frameCount + 1;
    let newFrame: Frame = {
      frameId: newFrameId,
      isFirstFrame: firstFrame,
      lastElementRef: React.createRef<HTMLDivElement | null>(),
      images: frameImages,
      nextPaginationId: lastImage.paginationId,
    };

    setFrameCount(newFrameId);
    setLastFrame(newFrame);
    setFrames((prev) => [...prev, newFrame]);
  };
  const updateCollectionTitle = async () => {
    const collection: any = await Api.getCollection(collectionId);
    const appCtxLanguage = appCtx.language;
    const translation = collection.translations.filter((t: { languageCode: string }) => t.languageCode === appCtxLanguage)[0];
    const languageCode = translation !== undefined ? translation.languageCode : "en";
    console.log(languageCode);
    const collectionTitleSv = collection.translations.filter((t: { languageCode: string }) => t.languageCode === languageCode)[0].title;
    setCollectionTitle(collectionTitleSv);
  };

  // initial fetch
  useEffect(() => {
    if (!collectionId) return;
    if (lastFrame) return;
    fetchNextFrame(false, true);
    updateCollectionTitle();
  }, [collectionId]);

  const pollLastElement = (lastFrame: Frame, timer: NodeJS.Timer) => {
    let lastElement = lastFrame.lastElementRef.current;
    if (!lastElement) return;

    let rightBorder = lastElement.getBoundingClientRect().right;

    // kill timer timer and fetch more images
    if (rightBorder < window.innerWidth) {
      clearInterval(timer);
      fetchNextFrame(false, false);
    }
  };

  useEffect(() => {
    if (!lastFrame) return;

    let poller = setInterval(() => {
      pollLastElement(lastFrame, poller);
    }, 100);
  }, [lastFrame]);

  const buttonStyles = orgCtx && orgCtx.organization ? { backgroundColor: orgCtx.organization.color } : { backgroundColor: "#000", border: "1px solid #ffffff" };
  const fullscreenClasses = handle.active ? "fullscreen-full-test" : "fullscreen-not-full-test";

  const toggleAnimation = (image: GalleryImage | null) => {
    console.log(image);
    console.log(isPaused ? "starting animation..." : "pausing animation...");
    setSelectedImage(image);
    setIsPaused(!isPaused);
  };

  const openGalleryImageDetailView = (image: GalleryImage) => {
    if (isPaused) return;
    setSelectedImage(image);
    setIsPaused(true);
  };

  const closeGalleryImageDetailView = () => {
    setSelectedImage(null);
    setIsPaused(false);
  };

  useEffect(() => {
    // Add classes to the body and html elements
    document.body.classList.add("turn-off-swiping-gestures");
    document.documentElement.classList.add("turn-off-swiping-gestures");
    // Clean up by removing the classes when the component unmounts
    return () => {
      document.body.classList.remove("turn-off-swiping-gestures");
      document.documentElement.classList.remove("turn-off-swiping-gestures");
    };
  }, []);

  return (
    <div className="gallery">
      <button
        className="btn"
        style={{ ...buttonStyles }}
        onClick={() => {
          history.push(`/organization/${organizationId}/collections/${collectionId}`);
        }}
      >
        {getTranslation("GALLERY.BACK")}
      </button>
      <button className="btn" onClick={handle.enter} style={{ margin: "30px 20px", ...buttonStyles }}>
        {getTranslation("GALLERY.START_FULLSCREEN")}
      </button>
      <div id="gallery-window" className="gallery-window">
        <FullScreen handle={handle} className={fullscreenClasses}>
          <h1 style={{ color: "white", textAlign: "center" }}>{collectionTitle}</h1>
          {frames.map((frame: Frame, idx: number) => {
            return (
              <div id={frame.frameId.toString()} key={idx.toString()} className={"gallery-frame " + (frame.isFirstFrame ? "first-frame " : " trailing-frame ")} style={{ animationPlayState: isPaused ? "paused" : "running" }}>
                {frame.images.map((image: GalleryImage, image_idx: number) => {
                  return (
                    <div onClick={() => openGalleryImageDetailView(image)} id={image.id} key={image.id} className={"gallery-img-wrapper " + (frame.isFirstFrame ? "scale-in-center" : "")} ref={image_idx === frame.images.length - 1 ? frame.lastElementRef : null}>
                      <img className="gallery-img" src={image.imageUrl || ""} alt={"gallery item"} />
                    </div>
                  );
                })}
              </div>
            );
          })}
          {selectedImage && <GalleryImageDetailView toggleAnimation={closeGalleryImageDetailView} selectedImage={selectedImage} organizationId={organizationId} />}
        </FullScreen>
      </div>
    </div>
  );
};

const GalleryImageDetailView = (props: { selectedImage: GalleryImage; toggleAnimation: (image: GalleryImage | null) => void; organizationId: string }) => {
  const [animationClass, setAnimationClass] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isNewEmail, setIsNewEmail] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
    setEmail("");
    setFirstName("");
    setLastName("");
    setIsNewEmail(null);
    setFocusedInput(null);
    setShowConfirmation(false);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };
  const handleFirstNameChange = (event: any) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event: any) => {
    setLastName(event.target.value);
  };

  const validateEmail = (email: any) => {
    if (!email) return false;
    const regex =
      /* eslint-disable */
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !(!email || regex.test(email) === false);
  };

  const validateName = (name: string) => {
    return name.trim().length !== 0;
  };

  const handleConfirm = () => {
    setShowConfirmation(true);
    setFocusedInput(null);
    // setTimeout(() => {
    //   setShowConfirmation(false);
    //   toggleForm();
    // }, 2000);
  };

  const checkEmail = async () => {
    if (!validateEmail(email)) return;
    const exists = await Api.checkIfEmailExists(email);
    if (exists) {
      Api.addToTagged(props.selectedImage, email);
      handleConfirm();
    } else {
      setIsNewEmail(true);
    }
  };

  const handleSubmit = async () => {
    if (isNewEmail === null) {
      checkEmail();
      return;
    }
    const registrationData = {
      email,
      firstName,
      lastName,
    };
    await Api.registerVisitor(registrationData, props.organizationId);
    Api.addToTagged(props.selectedImage, email);
    handleConfirm();
  };

  useEffect(() => {
    if (props.selectedImage) {
      setAnimationClass("enter");
      setTimeout(() => setAnimationClass("enter-active"), 10);
    } else {
      setAnimationClass("exit");
      setTimeout(() => setAnimationClass("exit-active"), 10);
    }

    return () => {
      if (!props.selectedImage) {
        setAnimationClass("");
      }
    };
  }, [props.selectedImage]);

  const closeDetailView = () => {
    setAnimationClass("exit");
    setTimeout(() => setAnimationClass("exit-active"), 10);
    setTimeout(() => {
      props.toggleAnimation(null);
    }, 200);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(closeDetailView, 15000); // 3000 ms = 3 seconds
    };

    // Events to reset the timer
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keypress", resetTimer);
    window.addEventListener("mousedown", resetTimer);
    window.addEventListener("touchstart", resetTimer);
    window.addEventListener("scroll", resetTimer);

    // Set the initial timer
    resetTimer();

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keypress", resetTimer);
      window.removeEventListener("mousedown", resetTimer);
      window.removeEventListener("touchstart", resetTimer);
      window.removeEventListener("scroll", resetTimer);
    };
  }, []);

  const [focusedInput, setFocusedInput] = useState<string | null>(null);

  const [inputValue, setInputValue] = useState<string>("");
  const handleKeyPress = (key: string) => {
    if (focusedInput) {
      let value = "";
      switch (focusedInput) {
        case "email":
          value = email;
          break;
        case "firstName":
          value = firstName;
          break;
        case "lastName":
          value = lastName;
          break;
      }
      let lowerKey = key.toLowerCase();
      if (lowerKey === "backspace") {
        value = value.slice(0, -1);
      } else if (lowerKey === "enter") {
        // Do nothing on Enter
        setFocusedInput(null);
      } else if (lowerKey === "space") {
        value += " ";
      } else {
        value += key;
      }

      switch (focusedInput) {
        case "email":
          console.log("email input 1 ", email);
          setEmail(value);
          console.log("email input 2", email);

          break;
        case "firstName":
          setFirstName(value);
          break;
        case "lastName":
          setLastName(value);
          break;
      }
    }
  };

  const handleFocus = (inputName: string) => {
    setFocusedInput(inputName);
  };

  return (
    <div className={`gallery-img-detail-view ${animationClass}`}>
      <div className="gallery-img-img">
        <img className="gallery-img-selected" src={props.selectedImage.imageUrl || ""} alt={"gallery item"} />
      </div>

      <div className="gallery-img-details-form-container">
        <div className="gallery-img-top-bar">
          <div className="close-button-container" onClick={() => closeDetailView()}>
            <AiOutlineClose />
          </div>
        </div>
        {!showForm && (
          <div className="gallery-img-details-container">
            <div className="gallery-img-text">
              {/* <div className="gallery-img-title">{props.selectedImage.title}</div> */}
              <div className="gallery-img-subtitle">
                <div className="gallery-img-photographer">
                  <AiFillCamera />
                  {props.selectedImage.isPhotographer && props.selectedImage.contributionUserFullName}
                  {!props.selectedImage.isPhotographer && props.selectedImage.otherPhotographer}
                </div>
                {props.selectedImage.date && <div>{props.selectedImage.openDate}</div>}
              </div>
              <div className="gallery-img-description">{props.selectedImage.description}</div>
            </div>
            <div className="gallery-img-information">
              <a className="location-link">{props.selectedImage.location}</a>
              <div>{props.selectedImage.license}</div>
            </div>
          </div>
        )}
        {showForm && !showConfirmation && (
          <div className="gallery-img-form-container">
            <p>Skapa din egen fotosamling på www.mittvasternorrland.se Här kan du spara bilden som en favoritbild. Du behöver ha ett konto hos oss. Om du inte har ett konto redan skapas det automatiskt när du anger dina kontaktuppgifter, och en inloggningslänk skickas till angiven e-postadress. Vi sparar endast information om e-postadress samt för och efternamn.</p>

            <div className="gallery-img-form">
              <p>Ange din e-post adress</p>

              <input onFocus={() => handleFocus("email")} spellCheck="false" type="text" placeholder="Email" value={email} onChange={handleEmailChange} />
              {isNewEmail && (
                <>
                  <input onFocus={() => handleFocus("firstName")} spellCheck="false" type="text" placeholder="Firstname" value={firstName} onChange={handleFirstNameChange} />
                  <input onFocus={() => handleFocus("lastName")} spellCheck="false" type="text" placeholder="Lastname" value={lastName} onChange={handleLastNameChange} />
                </>
              )}
            </div>
            <div className="gallery-img-form-error">{errorMessage}</div>
          </div>
        )}
        {showConfirmation && (
          <div className="gallery-img-confirmation">
            Hej, Vad roligt, nu har du sparat din första bild som favorit till din egen fotosamling med bilder från Västernorrland! När du sparade bilden skapades ett konto på www.mittvasternorrland.se. Klicka på länken för att logga in och se bilden, och spara fler bilder till dina favoriter. Du kan också själv bidra med egna bilder till Mitt Västernorrland.
          </div>
        )}
        <div className="gallery-img-btn-container">
          {!showForm ? (
            <div className="gallery-img-show-form-btn" onClick={toggleForm}>
              <span className="gallery-img-show-form-btn-icon">
                <BsFillBookmarkFill />
              </span>
              <span className="gallery-img-show-form-btn-text">Spara som favorit</span>
            </div>
          ) : (
            showForm && (
              <>
                <div onClick={toggleForm}>Tillbaka</div>
                {!showConfirmation && <div onClick={handleSubmit}>Spara</div>}
              </>
            )
          )}
        </div>
      </div>

      {focusedInput && <VirtualKeyboard onKeyPress={handleKeyPress}></VirtualKeyboard>}
    </div>
  );
};

function formatDate(date: Date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`; // Months are zero indexed
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

import "../../css/keyboard.css";
import { BsShiftFill } from "react-icons/bs";
import { useApplicationContext } from "../../context/ApplicationContext";
const keys: string[][] = [
  ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
  ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "Å"],
  ["A", "S", "D", "F", "G", "H", "J", "K", "L", "Ö", "Ä"],
  ["Z", "X", "C", "V", "B", "N", "M", "@", "Backspace"],
  ["Shift", ".com", ".se", ".", "Enter"],
];

interface VirtualKeyboardProps {
  onKeyPress: (key: string) => void;
}

const VirtualKeyboard: React.FC<VirtualKeyboardProps> = ({ onKeyPress }) => {
  const [isShift, setIsShift] = useState(false);

  const handleButtonClick = (key: string) => {
    if (key === "Shift") {
      setIsShift(!isShift);
    } else {
      onKeyPress(isShift ? key.toUpperCase() : key.toLowerCase());
    }
  };

  return (
    <div className="virtual-keyboard">
      {keys.map((row, rowIndex) => (
        <div key={rowIndex} className="keyboard-row">
          {row.map((key) => (
            <button key={key} onClick={() => handleButtonClick(key)} className={`keyboard-button ${key.toLowerCase()}`}>
              {/* {key === "Shift" || key === "Backspace" || key === "Enter" ? key : !isShift ? key.toLowerCase() : key.toUpperCase()} */}
              {key === "Shift" ? <BsShiftFill /> : key === "Backspace" ? <BsBackspace /> : key === "Enter" ? <BsArrowReturnLeft /> : !isShift ? key.toLowerCase() : key.toUpperCase()}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default VirtualKeyboard;
