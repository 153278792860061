
import '../../css/App.css';
import { useState, useMemo, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import countryList from 'react-select-country-list'
import { CountryItem } from '../../types'


export type DropDownProps = {
  title: string
  onUpdate: Function
  selectedCountry: string | null
}

export const CountriesDropDown = ({ title, onUpdate, selectedCountry }: DropDownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const countries = useMemo(() => countryList(), [])
  const [countiresData, setCountriesData] = useState<CountryItem[]>([])


  useEffect(() => {
    let c: CountryItem[] = []
    let prioCountries: CountryItem[] = []
    let allCountries = countries.getData()
    allCountries.forEach((countryElement: CountryItem) => {
      switch (countryElement.value) {
        case 'DK':
        case 'FI':
        case 'NO':
        case 'SE':
          prioCountries.push(countryElement)
          break
        default:
          c.push(countryElement)
      }
    });
    setCountriesData([...prioCountries, ...c])

  }, [countries])


  const renderOptions = () => {
    return (
      countiresData.map((s: any) => (
        <div
          className="dd-list-item-test"
          key={s.value}
          onClick={() => {
            onUpdate(s)
            setIsOpen(false)
          }}
        >
          {s.label}
        </div>
      ))

    )
  }

  return (
    <div className="field-pair" onClick={() => setIsOpen(!isOpen)}>
      <label>{title}</label>
      <div className="wrapper">
        <button
          type="button"
          className="header-test header-test-c "
        >
          <div className="header-title-test">
            <div>{selectedCountry} </div>
          </div>
          <div style={{ lineHeight: '4px' }}>
            {isOpen
              ? <IoIosArrowUp size="2em" />
              : <IoIosArrowDown size="2em" />
            }
          </div>
        </button>
        {isOpen && (
          <div
            role="list"
            className="dd-list-test"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="dd-scroll-list-test">
              {renderOptions()}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}