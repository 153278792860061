
import '../../../css/App.css';
import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { socialsList } from '../../../helpers/constants'

export type SocialsPickerProps = {
  title: string
  removeSocial: Function
  onUpdate: Function
  currentSocials: Array<string>
  organizationColor: string
  translationFunction: Function
}

type SocialsOptionType = {
  id: string
  name: string
}

export const SocialsPicker = ({ title, removeSocial, onUpdate, currentSocials, organizationColor, translationFunction }: SocialsPickerProps) => {
  const [options] = useState<Array<SocialsOptionType>>([...socialsList, { id: 'Other', name: translationFunction('CREATE_CONTRIBUTION_PAGE.FIELD_SOCIALS_OTHER') }])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showOtherInput, setShowOtherInput] = useState<boolean>(false)
  const [otherOptionValue, setOtherOptionValue] = useState<string>('')


  const renderOptions = () => {
    return (
      options.map((s: SocialsOptionType) => (
        <div
          className="dd-list-item-test"
          key={s.id}
          onClick={() => {
            if (s.id === 'Other') {
              setShowOtherInput(true)
              setIsOpen(!isOpen)
              return
            }
            onUpdate('socials', s.name)
          }}
        >
          {s.name}
          {' '}
          {currentSocials && currentSocials.some((i: string) => i === s.name) && (
            <span >
              <AiOutlineCheck color="#2b6777" />
            </span>
          )}
        </div>
      ))

    )
  }

  const onUpdateOtherOptionInput = (value: string) => {
    setOtherOptionValue(value)
  }

  const addOtherOption = () => {
    if (!otherOptionValue.length) {
      setShowOtherInput(false)
      return
    }
    onUpdate('socials', otherOptionValue)
    setOtherOptionValue('')
    setShowOtherInput(false)
  }


  const handleItemClick = (e: any, s: string) => {
    if (!e) {
      e = window.event;
    }
    if (e) {
      e.cancelBubble = true;
      if (e.stopPropagation) {
        e.stopPropagation()
      };
    }

    removeSocial('socials', s)
  }

  return (
    <div className="field-pair" >
      <label>{title}</label>
      <div
        className="wrapper"
      >
        <button
          type="button"
          className="header-test"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <div
            className="header-title-test"
          >
            {currentSocials && currentSocials.length > 0 ?
              (<div className="options">
                {currentSocials.map((s: string) => (
                  <div className="option" key={s} onClick={(e: any) => handleItemClick(e, s)}>
                    <div className="option-text">{s}</div>
                    <div>
                      <AiOutlineClose size="1.2em" />
                    </div>
                  </div>
                ))}
              </div>
              ) : null}
          </div>
          <div style={{ lineHeight: '4px' }}>
            {isOpen
              ? <IoIosArrowUp size="2em" />
              : <IoIosArrowDown size="2em" />
            }
          </div>
        </button>
        {isOpen && (
          <div
            role="list"
            className="dd-list-test"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="dd-scroll-list-test"
            >
              {renderOptions()}
            </div>
          </div>
        )}
        <div>
          {showOtherInput ? (
            <div className="other-socails-input-container">
              <label htmlFor={'other'}>{translationFunction('CREATE_CONTRIBUTION_PAGE.FIELD_SOCIALS_OTHER_INPUT_TITLE')}</label>
              <div className="other-socials-actions">
                <input
                  value={otherOptionValue}
                  type="text"
                  id="other"
                  name="other"
                  onChange={e => onUpdateOtherOptionInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                    }
                  }} />
                <div className="other-socials-actions-buttons">
                  <button className="btn" style={{ backgroundColor: organizationColor }} disabled={!otherOptionValue.length} onClick={addOtherOption}>{translationFunction('GLOBAL.ADD')}</button>
                  <button className="btn" style={{ backgroundColor: organizationColor }} onClick={() => setShowOtherInput(false)}>{translationFunction('GLOBAL.CANCEL')}</button>
                </div>
              </div>
            </div>
          ) : null}
        </div>

      </div>
    </div>
  )
}
