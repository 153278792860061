import '../../css/Footer.css';
import { useOrganizationContext } from "../../context/OrganizationContext";
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { ROUTE } from '../../routes'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'

export const Footer = () => {
  const [showOrganizationInformation, setShowOrganizationInformation] = useState<boolean>(false)
  const orgContext = useOrganizationContext()

  const { getTranslation } = useCustomTranslation(orgContext?.organization?.id || '')

  useEffect(() => {
    if (orgContext && orgContext.organization) {
      setShowOrganizationInformation(true)
    }
    else {
      setShowOrganizationInformation(false)
    }

  }, [orgContext?.organization, orgContext])

  return (
    <footer>
      <div className="app-footer">
        <div className="footer-content">
          <div className="footer-list-wrapper list1">
            <p>{getTranslation('FOOTER.ABOUT.HEADING')}</p>
            <div className="footer-div-divider"></div>
            <ul className="footer">
              <li><Link to={ROUTE.ABOUT}>{getTranslation('FOOTER.ABOUT.SYSTEM_LINK')}</Link></li>
              {showOrganizationInformation && (<li><Link to={`/organization/${orgContext?.organization?.id}/help`}>{getTranslation('FOOTER.ABOUT.HELP_LINK')}</Link></li>)}
            </ul>
          </div>

          {showOrganizationInformation && (
            <div className="footer-list-wrapper list2">
              <p>{getTranslation('FOOTER.CONTACT.HEADING')}</p>
              <div className="footer-div-divider"></div>
              <ul className="footer">
                <li>{getTranslation('FOOTER.CONTACT.EMAIL')}{orgContext?.organization?.contactEmail}</li>
                <li><Link to={{ pathname: `/organization/${orgContext?.organization?.id}/terms-and-conditions` }}>{getTranslation('FOOTER.CONTACT.TERMS_&_CONDITIONS')}</Link></li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
}

