import { useState, useEffect } from 'react'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Api } from '../../api/api'
import { CollectionList } from './CollectionList'
import { ROUTE } from '../../routes'
import { useOrganizationContext } from '../../context/OrganizationContext'
import { useUserContext } from '../../context/UserContext'
import { Can } from '../../helpers/permissionsHelper'
import { RoutingProps } from '../../types/types'
import { getOrganizationKeyInLang } from '../../helpers/helperFunctions'
import { ToggleSlider } from '../common/ToggleSlider'
import { RecentPosts } from '../admin/RecentPosts'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'

const CollectionsComponent = () => {
  const { i18n } = useTranslation()
  let history = useHistory();
  const location = useLocation()
  const organizationContext = useOrganizationContext()
  const userContext = useUserContext()

  const [collections, setCollections] = useState([])
  const [organizationId, setOrganizationId] = useState<string | null>(null)
  const [organizationName, setOrganizationName] = useState<string>('')
  const [navigateTo, setNaviagteTo] = useState<RoutingProps | null>(null)
  const [showRecentPosts, setShowRecentPosts] = useState<boolean>(false)

  const { getTranslation } = useCustomTranslation(organizationId || '')
  const redirectToOrgAbout = () => {
    const redirectUrl = `/organization/${organizationId}/about`
    setNaviagteTo({ pathname: redirectUrl })
  }


  const redirectToEditCollection = () => {
    if (!organizationContext || !organizationContext.organization) return
    const redirectUrl = `/organization/${organizationId}/authenticated/collections/edit-collection`
    setNaviagteTo({ pathname: redirectUrl, state: { collections, org: organizationContext.organization } })
  }

  useEffect(() => {
    const getCollections = async (organizationId: string) => {
      try {
        const collections = await Api.getCollections(organizationId)
        setCollections(collections)
      } catch (error) {
        console.error('There was an error: ', error)
      }
    }

    if (!organizationContext || !organizationContext.organization) {
      history.push(ROUTE.HOME)
    } else {
      setOrganizationId(organizationContext.organization.id)
      setOrganizationName(organizationContext.organization.name)
      getCollections(organizationContext.organization.id)
    }
  }, [history, location, organizationContext])

  if (!organizationId) {
    return <div className="loader--loading" style={{ height: '100vh' }}></div>
  }

  if (navigateTo) {
    return <Redirect to={navigateTo} />
  }

  return (
    <div className="App">
      <div className="app-content collections-page">
        <div className="org-banner" style={{ backgroundColor: `${organizationContext?.organization?.color}` }} >
          {organizationContext?.organization?.logo_url !== "" ? <img src={organizationContext?.organization?.logo_url} alt="organization logo" /> : ""}
          <h1>{organizationContext?.organization?.name}</h1>
          <h2>{organizationContext && organizationContext.organization ? getOrganizationKeyInLang(organizationContext.organization, 'description', i18n.language) : ""}</h2>
          <span onClick={redirectToOrgAbout} className="click-for-more" >{getTranslation('COLLECTIONS_PAGE.READ_MORE')}</span>
        </div>
        <Can
          userRoles={userContext?.loggedInUser?.roles}
          requiredPermission={userContext?.actionRoles?.ADMIN}
          organizationId={organizationContext?.organization?.id}
          isPlatformAdmin={userContext?.userIsPlaformAdmin}
          yes={() => (
            <div className="admin-panel">
              {collections.length > 0 && <div className="m-1 admin-panel-option-container" onClick={redirectToEditCollection}>
                <p>{getTranslation('ADMIN.EDIT_COLLECTION_LINK')}</p>
              </div>}
              <div className="m-1 admin-panel-option-container-multiple" >
                <p>{getTranslation('ADMIN.RECENT_POSTS.TITLE')}</p>
                <ToggleSlider onText={getTranslation('GLOBAL.ON')} offText={getTranslation('GLOBAL.OFF')} on={showRecentPosts} updateState={() => setShowRecentPosts(!showRecentPosts)} />
              </div>
            </div>
          )}
        />
        <CollectionList collections={collections} organizationId={organizationId} organizationName={organizationName} />
        <Can
          userRoles={userContext?.loggedInUser?.roles}
          requiredPermission={userContext?.actionRoles?.ADMIN}
          organizationId={organizationContext?.organization?.id}
          isPlatformAdmin={userContext?.userIsPlaformAdmin}
          yes={() => (
            <RecentPosts show={showRecentPosts} organizationColor={organizationContext?.organization?.color || '#556271'} organizationId={organizationId} />
          )}
        />
      </div>
    </div >
  );
}


export const Collections = ({ ...props }) => {
  const history = useHistory();
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="collections" router={history}>
        <CollectionsComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <CollectionsComponent {...props} />
  )
}

