import '../../css/EditOrganization.css'
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from 'react-router-dom'
import {
  Language,
  PlatformTranslationsType,
  UpdatePlatformPayload,
  PlatformTranslation,
  TranslationType,
  FeatureTypes
} from "../../types"
import { ROUTE } from '../../routes'
import { useUserContext } from '../../context/UserContext';
import { useApplicationContext } from '../../context/ApplicationContext';
import { AdminApi } from '../../api/admin';
import { toastNotifyError, toastNotifySuccess } from '../common/ToastMessage'
import { PlatfromTranslations } from './PlatformTranslations'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'

type TwoFieldType = 'howItWorks' | 'origin' | 'about'
type TwoFieldKeyTypes = 'title' | 'text'

const emptySystemPageObject = {
  whatIs: '',
  howItWorks: {
    title: '',
    text: ''
  },
  featuresTitle: '',
  features: {
    dynamic: {
      title: '',
      text: ''
    },
    hosting: {
      title: '',
      text: ''
    },
    export: {
      title: '',
      text: ''
    },
    privacy: {
      title: '',
      text: ''
    },
  },
  origin: {
    title: '',
    text: ''
  },
  about: {
    title: '',
    text: ''
  }
}

const EditPlatformPagesComponent = () => {
  const location = useLocation()
  const history = useHistory()
  const userCtx = useUserContext()
  const appCtx = useApplicationContext()
  const { t } = useTranslation()
  const [apiLanguages, setApiLanguages] = useState<Language[] | null>(null)
  const [languages, setLanguages] = useState<PlatformTranslationsType>()
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const handleThreeKeyUpdate = (language: string, firstKey: 'features', secondKey: FeatureTypes, thirdKey: TwoFieldKeyTypes, value: any) => {
    if (languages && language in languages) {
      let prevLangValues = languages[language]
      prevLangValues.translations[firstKey][secondKey][thirdKey] = value
      setLanguages(prev => ({ ...prev, [language]: prevLangValues }))
    }
  }

  const handleTwoKeyUpdate = (language: string, firstKey: TwoFieldType, secondKey: TwoFieldKeyTypes, value: any) => {

    if (languages && language in languages) {
      let prevLangValues = languages[language]
      prevLangValues.translations[firstKey][secondKey] = value
      setLanguages(prev => ({ ...prev, [language]: prevLangValues }))
    }
  }
  const handleTranslationUpdate = (language: string, key: keyof TranslationType, value: any) => {
    if (languages && language in languages) {
      let splitKeys = key.split('.');
      if (splitKeys.length === 1) {
        let prevLangValues = languages[language]
        prevLangValues.translations[key] = value
        setLanguages(prev => ({ ...prev, [language]: prevLangValues }))
      }

      if (splitKeys.length === 2) {
        handleTwoKeyUpdate(language, splitKeys[0] as TwoFieldType, splitKeys[1] as TwoFieldKeyTypes, value)

      }
      if (splitKeys.length === 3) {
        handleThreeKeyUpdate(language, splitKeys[0] as 'features', splitKeys[1] as FeatureTypes, splitKeys[2] as TwoFieldKeyTypes, value)
      }
    }
  }

  const verifyAllFieldsFilledIn = (translations: TranslationType): boolean => {
    let isValid = true
    isValid = isValid && translations.whatIs.length > 0
    isValid = isValid && translations.featuresTitle.length > 0
    isValid = isValid && translations.howItWorks.title.length > 0
    isValid = isValid && translations.howItWorks.text.length > 0
    isValid = isValid && translations.origin.title.length > 0
    isValid = isValid && translations.origin.text.length > 0
    isValid = isValid && translations.about.title.length > 0
    isValid = isValid && translations.about.text.length > 0
    const features = Object.keys(translations.features)
    features.forEach((feature: string) => {

      isValid = isValid && translations.features[feature as FeatureTypes].title.length > 0
      isValid = isValid && translations.features[feature as FeatureTypes].text.length > 0
    })
    return isValid
  }


  const verifyTranslations = () => {
    if (!languages) return false
    let entries = Object.entries(languages)
    let isValid = entries.reduce((acc, current) => {
      if (current[1].required) {
        let reqLangValid = verifyAllFieldsFilledIn(current[1].translations)
        return acc && reqLangValid
      }

      let translationsCopy = JSON.stringify(current[1].translations)
      // not required lang is not empty, check that everything is filled in
      if (translationsCopy !== JSON.stringify(emptySystemPageObject)) {
        let nonReqLangValid = verifyAllFieldsFilledIn(current[1].translations)
        return acc && nonReqLangValid
      }
      return acc && true
    }, true)
    return isValid
  }


  const submissionFormatTranslations = () => {
    if (!languages) return ""
    let formatted: any = []
    for (const [key, value] of Object.entries(languages)) {
      let translationsCopy = JSON.stringify(value.translations)
      if (translationsCopy === JSON.stringify(emptySystemPageObject)) {
        continue
      }

      let langObject = {
        languageCode: key,
        translations: value.translations,
      }
      formatted.push(langObject)
    }
    return formatted
  }

  const handleSubmit = async () => {
    if (!verifyTranslations()) {
      return
    }

    let formatted = submissionFormatTranslations()
    const data: UpdatePlatformPayload = {
      translations: formatted,
    }
    setIsUpdating(true)
    try {
      const response = await AdminApi.updateSystemPages(data)
      appCtx.updateSystemPageTranslations(response)
      toastNotifySuccess(t('GLOBAL.UPDATE_SUCCESS'))
    } catch (error: any) {

      toastNotifyError(error.respone ? error.response.data.message : 'Something went wrong, could not update translations')

    } finally {
      setIsUpdating(false)
    }
  }

  useEffect(() => {
    const setUpLanguages = (fetchedLanguages: Language[]) => {
      let x: PlatformTranslationsType = {}
      fetchedLanguages.forEach((l: Language) => {
        let hasTranslation = appCtx && appCtx.systemPageTranslations ? appCtx.systemPageTranslations.filter((t: PlatformTranslation) => t.languageCode === l.language) : []
        let initialTranslation: TranslationType = hasTranslation.length ? hasTranslation[0].translations : JSON.parse(JSON.stringify(emptySystemPageObject))
        x[l.language] = {
          required: l.required,
          language: l.language,
          translations: initialTranslation,
        }
      });
      setLanguages(x)

    }
    const getLanguages = async () => {
      const response = await AdminApi.getLanguages()
      setApiLanguages(response)
      setUpLanguages(response)
    }

    if (!userCtx || !userCtx.loggedInUser) {
      history.push(ROUTE.HOME)
    } else {

      if (!userCtx.userIsPlaformAdmin) {
        history.push(ROUTE.HOME)
      }
      if (!apiLanguages) {
        getLanguages()
      } else {
        setUpLanguages(apiLanguages)
      }
    }
  }, [location, history, userCtx, apiLanguages, t, appCtx])

  const submitButtonClasses = isUpdating ? 'loader-btn loader-btn--loading btn-stnd-al m-t-50 btn-default' : 'btn-default loader-btn btn-stnd-al m-t-50'
  if (!languages) {
    return (<div className="loader--loading" style={{ height: '100vh' }}></div>)
  }
  return (
    <div className="App">
      <div className="app-content edit-plaform-page">
        <h1>{t('PLATFORM.EDIT_PLATFORM_TITLE')}</h1>
        <div className="edit-organization-container">
          <div className="edit-organization-form-container">
            <PlatfromTranslations
              languages={languages}
              onTranslationUpdate={handleTranslationUpdate}
            />
          </div>
          <button className={submitButtonClasses} onClick={handleSubmit} >
            <span className="loader-btn__text">{t('GLOBAL.SAVE')}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export const EditPlatformPages = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="EditPlatformPages" router={history}>
        <EditPlatformPagesComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <EditPlatformPagesComponent {...props} />
  )
}