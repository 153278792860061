
import { useState } from 'react'
import { Text } from '../CreateContribution/formComponents/FormComponents'
type AddQuestionsProps = {
  title: string
  addQuestion: Function
  buttonColor: string
  addButtonText: string
}
export const AddQuestions = ({ title, addQuestion, buttonColor, addButtonText }: AddQuestionsProps) => {
  const [questionToAdd, setQUestionToAdd] = useState<string>('')

  const onAddQuestion = () => {
    if (questionToAdd === '') {
      return
    }
    let newQuestion = questionToAdd
    addQuestion(newQuestion)
    setQUestionToAdd('')
  }
  return (
    <div className="settings-q-wrapper">
      <Text
        title={title}
        name={title}
        onUpdate={(name: string, value: string) => setQUestionToAdd(value)}
        handleKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}
        value={questionToAdd} />
      <button className="btn btn-secondary" style={{ backgroundColor: buttonColor }} onClick={onAddQuestion}>{addButtonText}</button>
    </div>
  )
}