import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import * as en from './languages/en.json'
import * as sv from './languages/sv.json'
import * as dk from './languages/dk.json'
import * as fi from './languages/fi.json'

const langEn = en as any
const langSV = sv as any
const langDK = dk as any
const langFI = fi as any

const resources = {
    en: {
        common: langEn.default
    },
    sv: {
        common: langSV.default
    },
    dk: {
        common: langDK.default
    },
    fi: {
        common: langFI.default
    }
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources,
        defaultNS: 'common',
        // lng: "en", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });


export const addTranslation = (newTranslations: string, nameSpaceName: string, langauge: string) => {
    i18n.addResourceBundle(langauge, nameSpaceName, newTranslations);
}

export default i18n