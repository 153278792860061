
export const findExistingMapScript = (url: string) => {
  const scripts = document.getElementsByTagName('script');
  // Go through existing script tags, and return google maps api tag when found.
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.indexOf(url) === 0) {
      // return scripts[i];
      return true
    }
  }
  return false
}

export const createMapScriptUrl = () => {
  const userLang = navigator.language
  const char = userLang.indexOf('-')
  const language = userLang.substring(0, char)
  const region = userLang.substring(char + 1)
  const key = process.env.REACT_APP_GOOGLE_KEY || ''

  const mapURL = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&region=${region}&language=${language}&callback=initMap&v=weekly`
  return mapURL
}
export const loadMapApi = (mapUrl: string) => {
  const googleMapScript = document.createElement('script');
  googleMapScript.src = mapUrl;
  googleMapScript.async = true;
  googleMapScript.defer = true;
  window.document.body.appendChild(googleMapScript);

  return googleMapScript;
};