import * as Accordion from '@radix-ui/react-accordion';
import { HiOutlineChevronDown } from 'react-icons/hi'
import { BsExclamationCircle } from 'react-icons/bs'
import { PlatformTranslationsType } from '../../types'
import { TextEditor } from '../common/TextEditor'
import { Text, TextArea } from '../CreateContribution/formComponents/FormComponents'
import { _handleKeyDownPreventDefault } from '../../helpers/helperFunctions'


type Props = {
  languages: PlatformTranslationsType
  onTranslationUpdate: Function
}
export const PlatfromTranslations = ({ languages, onTranslationUpdate }: Props) => {

  const handleFormUpdate = (lang: string, key: string, value: string) => {
    onTranslationUpdate(lang, key, value)
  }

  const translationSection = () => {
    let entries = Object.entries(languages)
    return entries.map(l => {
      return (
        <Accordion.Item value={l[0]} className="create-collection-accordion-item " key={l[0]}>
          <Accordion.Header className="create-collection-accordion-header">
            {l[1].required ? (
              <div>
                {l[0]}
                <BsExclamationCircle style={{ marginLeft: '10px' }} />
              </div>
            ) : l[0]}
            <Accordion.Trigger className="create-collection-accordion-trigger" >
              <HiOutlineChevronDown className="create-collection-accordion-trigger-icon" />
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="create-collection-accordion-content">
            <form onSubmit={e => {
              e.preventDefault()
            }} name="system-page-transaltions-form">
              <fieldset>
                <div className="section-scope">
                  {/* <label htmlFor={"What is Connect To Collect"} className="section-label">What is Connect To Collect</label> */}
                  <TextArea
                    title="What is Connect To Collect"
                    name="whatIs"
                    value={l[1].translations.whatIs}
                    onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'whatIs', newValue) }}
                    handleKeyDown={_handleKeyDownPreventDefault}
                  />
                </div>
                <div className="section-divider" />
                <div className="section-scope">
                  <label className="section-label">How it Works Section</label>
                  <Text
                    title="Section Title"
                    name="howItWorksTitel"
                    onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'howItWorks.title', newValue) }}
                    value={l[1].translations.howItWorks.title}
                    handleKeyDown={_handleKeyDownPreventDefault}
                  />
                  <TextArea
                    title="Section Text"
                    name="howItWorksText"
                    value={l[1].translations.howItWorks.text}
                    onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'howItWorks.text', newValue) }}
                    handleKeyDown={_handleKeyDownPreventDefault}
                  />

                </div>
                <div className="section-divider" />
                <div className="section-scope">
                  <label className="section-label">Features Section</label>
                  <Text
                    title="Section Title"
                    name="featuresTitel"
                    onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'featuresTitle', newValue) }}
                    value={l[1].translations.featuresTitle}
                    handleKeyDown={_handleKeyDownPreventDefault}
                  />

                  <label htmlFor={"features-list"} style={{ marginTop: '30px', marginBottom: '10px' }} className="section-label">List of features</label>
                  <div className="field-pair">
                    <Text
                      title="Dynamic Feature Title"
                      name="dynamic"
                      value={l[1].translations.features.dynamic.title}
                      onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'features.dynamic.title', newValue) }}
                      handleKeyDown={_handleKeyDownPreventDefault}
                    />
                    <TextArea
                      title="Dynamic Feature Text"
                      name="dynamicText"
                      value={l[1].translations.features.dynamic.text}
                      onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'features.dynamic.text', newValue) }}
                      handleKeyDown={_handleKeyDownPreventDefault}
                    />
                  </div>
                  <div className="field-pair">
                    <Text
                      title="Hosting/Storage Feature Title"
                      name="hosting"
                      value={l[1].translations.features.hosting.title}
                      onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'features.hosting.title', newValue) }}
                      handleKeyDown={_handleKeyDownPreventDefault}
                    />
                    <TextArea
                      title="Hosting Feature Text"
                      name="hostingText"
                      value={l[1].translations.features.hosting.text}
                      onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'features.hosting.text', newValue) }}
                      handleKeyDown={_handleKeyDownPreventDefault}
                    />
                  </div>
                  <div className="field-pair">
                    <Text
                      title="Export Feature Tiitle"
                      name="export"
                      value={l[1].translations.features.export.title}
                      onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'features.export.title', newValue) }}
                      handleKeyDown={_handleKeyDownPreventDefault}
                    />
                    <TextArea
                      title="Export Feature Text"
                      name="exportText"
                      value={l[1].translations.features.export.text}
                      onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'features.export.text', newValue) }}
                      handleKeyDown={_handleKeyDownPreventDefault}
                    />
                  </div>
                  <div className="field-pair">
                    <Text
                      title="Private Feature Tiitle"
                      name="private"
                      value={l[1].translations.features.privacy.title}
                      onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'features.privacy.title', newValue) }}
                      handleKeyDown={_handleKeyDownPreventDefault}
                    />
                    <TextArea
                      title="Private Feature Text"
                      name="privateText"
                      value={l[1].translations.features.privacy.text}
                      onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'features.privacy.text', newValue) }}
                      handleKeyDown={_handleKeyDownPreventDefault}
                    />
                  </div>
                </div>
                <div className="section-divider" />
                <div className="section-scope">
                  <label className="section-label">Origin Section</label>
                  <div className="field-pair">
                    <Text
                      title="Section Title"
                      name="originTitle"
                      value={l[1].translations.origin.title}
                      onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'origin.title', newValue) }}
                      handleKeyDown={_handleKeyDownPreventDefault}
                    />
                    <label style={{ marginTop: '15px' }}>Section Text</label>
                    <TextEditor
                      initialValue={l[1].translations.origin.text}
                      onChange={(newValue: string) => { handleFormUpdate(l[0], 'origin.text', newValue) }}
                    />
                  </div>
                </div>
                <div className="section-divider" />
                <div className="section-scope">
                  <label className="section-label">About Us Section</label>
                  <div className="field-pair">
                    <Text
                      title="Section Title"
                      name="aboutUsTitle"
                      value={l[1].translations.about.title}
                      onUpdate={(name: string, newValue: string) => { handleFormUpdate(l[0], 'about.title', newValue) }}
                      handleKeyDown={_handleKeyDownPreventDefault}
                    />
                    <label style={{ marginTop: '15px' }}>Section Text</label>
                    <TextEditor
                      initialValue={l[1].translations.about.text}
                      onChange={(newValue: string) => { handleFormUpdate(l[0], 'about.text', newValue) }}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </Accordion.Content>
        </Accordion.Item>
      )
    })
  }

  return (
    <div className="edit-organization-translations-section">
      <h2>System Page Translations</h2>
      <Accordion.Root type="multiple" className="create-collection-accordion-root">
        {translationSection()}
      </Accordion.Root>
    </div>
  )

}
