import * as Accordion from '@radix-ui/react-accordion';
import { HiOutlineChevronDown } from 'react-icons/hi'
import { BsExclamationCircle } from 'react-icons/bs'
import { HelpPageTranslationsType } from '../../types'
import { TextEditor } from '../common/TextEditor'


type Props = {
  languages: HelpPageTranslationsType
  onTranslationUpdate: Function
  editorTitle: string
  sectionTitle: string
}
export const HelpPageTranslations = ({ languages, onTranslationUpdate, editorTitle, sectionTitle }: Props) => {

  const handleFormUpdate = (lang: string, value: string) => {
    onTranslationUpdate(lang, value)
  }

  const translationSection = () => {
    let entries = Object.entries(languages)
    return entries.map(l => {
      return (
        <Accordion.Item value={l[0]} className="create-collection-accordion-item " key={l[0]}>
          <Accordion.Header className="create-collection-accordion-header">
            {l[1].required ? (
              <div>
                {l[0]}
                <BsExclamationCircle style={{ marginLeft: '10px' }} />
              </div>
            ) : l[0]}
            <Accordion.Trigger className="create-collection-accordion-trigger" >
              <HiOutlineChevronDown className="create-collection-accordion-trigger-icon" />
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="create-collection-accordion-content">
            <form onSubmit={e => {
              e.preventDefault()
            }} name="createCollectionForm">
              <fieldset>
                <div className="field-pair">
                  <label htmlFor={"title"}>{editorTitle}</label>
                  <TextEditor
                    initialValue={l[1].translation}
                    onChange={(newValue: string) => { handleFormUpdate(l[0], newValue) }}
                  />
                </div>
              </fieldset>
            </form>
          </Accordion.Content>
        </Accordion.Item>
      )
    })
  }

  return (
    <div className="edit-organization-translations-section">
      <h2>{sectionTitle}</h2>
      <Accordion.Root type="multiple" className="create-collection-accordion-root">
        {translationSection()}
      </Accordion.Root>
    </div>
  )

}
