import '../../css/CreateContribution.css';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from 'react-router-dom'
import { Api } from '../../api/api'
import {
  CollectionType,
  Event,
  ContributionFormType,
  LicenseType,
  FilesUpLoadState,
  MediaListItem,
  KeyString,
  SelectedItem,
  MediaFile
} from '../../types'
import { _handleKeyDownPreventDefault, getCollectionKeyInLang } from '../../helpers/helperFunctions'
import { useUserContext } from '../../context/UserContext'
import { ImageUploader } from './formComponents/ImageUploader'
import { useOrganizationContext } from '../../context/OrganizationContext';
import { findExistingMapScript, createMapScriptUrl, loadMapApi } from '../../helpers/mapFunctions'
import { toastNotifyError } from '../common/ToastMessage'
import { SimpleDialog } from '../common/SimpleDialog'
import { userIsComplete } from '../../helpers/permissionsHelper'
import { UploadProcess } from './formComponents/UploadProcess';
import { ROUTE } from '../../routes'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'

const CreateContributionComponent = () => {
  const { i18n } = useTranslation()
  let history = useHistory();
  const location = useLocation()
  const userContext = useUserContext()
  const orgContext = useOrganizationContext()

  const [collection, setCollection] = useState<CollectionType | undefined>(undefined)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [isPublic, setIsPublic] = useState<boolean>(true)
  const [mediaFiles, setMediaFiles] = useState<MediaListItem[] | null>(null)
  const [mediaFilesWithErrors, setMediaFilesWithErrors] = useState<string[]>([])
  const [selectedMediaItem, setSelectedMediaItem] = useState<SelectedItem | null>(null)
  const [relatedFiles, setRelatedFiles] = useState<FilesUpLoadState>({
    selectedFiles: null,
  })
  const [licences, setLicenses] = useState<LicenseType[]>([])
  const [filesComment, setFilesComment] = useState<string>('')
  const [questionAnswers, setQuestionAnswers] = useState<KeyString>({})
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [acceptTc, setAcceptTc] = useState<boolean>(false)
  const [mediaUploadDisabled, setMediaUploadDisabled] = useState(false)
  const [showRedirectOptions, setShowRedirectOptions] = useState<boolean>(false)

  const { getTranslation } = useCustomTranslation(orgContext?.organization?.id || '')

  useEffect(() => {
    const getLicences = async () => {
      try {
        const response = await Api.getLicences()
        setLicenses(response)
      } catch (error: any) {
        console.error('error', error)
      }
    }
    if (!userContext || !userContext.loggedInUser || !orgContext || !orgContext.organization) {
      history.goBack()
    }

    let user = userContext!.loggedInUser!
    let organizationUserFields = orgContext!.organization!.registrationFields

    if (!location.state) {
      history.push('/')
    } else {
      if (!userIsComplete(user, organizationUserFields)) {
        setShowRedirectOptions(true)
      }
      const { collectionData }: any = location.state
      getLicences()
      setCollection(collectionData)
      if (!collectionData.hasTerms) {
        setAcceptTc(true)
      }
      const url = createMapScriptUrl()
      if (findExistingMapScript(url)) {
        setScriptLoaded(true);
      } else {
        const googleMapScript = loadMapApi(url);
        googleMapScript.addEventListener('load', function () {
          setScriptLoaded(true);
        });
      }

    }
  }, [history, location, userContext, orgContext])

  const onRelatedFilesInputChangeHandler = (event: Event<HTMLInputElement>) => {
    const files = event.target.files
    if (files !== null) {
      setRelatedFiles({ selectedFiles: files })
    }
  }

  const onRelatedCommentUpdateHandler = (value: string) => {
    setFilesComment(value)
  }

  const renderCollectionQuestions = () => {
    if (!collection?.questions || !collection.questions.length) {
      return null
    }

    const questions = collection.questions
    return (
      <div className="text-left">
        <h3>{getTranslation('CREATE_CONTRIBUTION_PAGE.COLLECTION_QUESTIONS_HEADER')}</h3>
        {questions.map((q: string) => {
          return (
            <div style={{ display: 'grid' }} key={q}>
              <label htmlFor={q} className="m-1">{q}</label>
              <input value={questionAnswers[q]} type="text" id={q} name={q} placeholder="" onChange={e => setQuestionAnswers(prev => ({ ...prev, [q]: e.target.value }))} onKeyDown={(e) => _handleKeyDownPreventDefault(e)} />
            </div>
          )
        })}
      </div>
    )
  }

  const updateMediaFiles = (files: MediaListItem[]) => {
    setMediaFiles(files)
  }

  const verifyAllFiles = (files: MediaListItem[]) => {
    const requiredFields: string[] = []
    if (collection?.contributionFields) {
      for (const [key, value] of Object.entries(collection!.contributionFields)) {
        if (value) {
          requiredFields.push(key)
        }
      }
    }

    if (requiredFields.length === 0) {
      return []
    }

    const filesWithErrors = files.reduce((acc: string[], currentFile) => {
      let hasError: string | null = null
      for (const field of requiredFields) {
        if (!currentFile.formValues) {
          hasError = currentFile.mediaFile.URL
          break
        }

        let value = field in currentFile.formValues ? currentFile.formValues[field as keyof ContributionFormType] : ''
        switch (typeof value) {
          case 'string':
            if (value.length === 0) {
              hasError = currentFile.mediaFile.URL
            }
            break
          case 'object':
            if (!value) {
              hasError = currentFile.mediaFile.URL
            }

            break
          // case 'boolean':
          //   if (!value) {
          //     hasError = currentFile.mediaFile.URL
          //   }
        }
      }
      if (requiredFields.includes('isPhotographer') && !(currentFile.formValues?.isPhotographer || currentFile.formValues?.otherPhotographer || (currentFile.formValues && currentFile.formValues.otherPhotographer && currentFile.formValues.otherPhotographer.length === 0))) {
        hasError = currentFile.mediaFile.URL
      }

      if (hasError) {
        return [...acc, hasError]
      }
      return acc
    }, [])

    return filesWithErrors
  }

  const handleSubmit = async () => {
    if (!mediaFiles || !userContext || !userContext.loggedInUser || !collection) {
      return
    }

    setMediaFilesWithErrors([])

    const filesWithErrors = verifyAllFiles(mediaFiles)
    if (filesWithErrors.length) {
      setMediaFilesWithErrors(filesWithErrors)
      const alertMessage = `${getTranslation('CREATE_CONTRIBUTION_PAGE.MISSING_VALUE_ERROR')}`
      toastNotifyError(alertMessage)
      return
    }

    if (collection.questions && collection.questions.length) {
      const expectedAmoutOfAnswers = collection.questions.length
      if (expectedAmoutOfAnswers !== Object.keys(questionAnswers).length || !Object.values(questionAnswers).reduce((acc, currentValue) => { acc = acc && currentValue.length > 0; return acc }, true as boolean)) {
        toastNotifyError(`${getTranslation('CREATE_CONTRIBUTION_PAGE.MISSING_QUESTION_ANSWERS_ERROR')}`)
        return
      }
    }

    if (collection.hasTerms && !acceptTc) {
      toastNotifyError(getTranslation('CREATE_CONTRIBUTION_PAGE.TERMS_NOT_ACCEPTED_ERROR'))
      return
    }

    const data = new FormData()
    let mediaData: { [id: string]: any } = {}
    let medias: File[] = []
    mediaFiles.forEach((f: MediaListItem) => {
      let copy = JSON.parse(JSON.stringify(f));

      if (copy.formValues) {
        if (copy.formValues.otherPhotographer === '') {
          copy.formValues.otherPhotographer = null
        }
        let copyFileName = JSON.parse(JSON.stringify(f.mediaFile.File.name))
        mediaData[copyFileName] = copy.formValues
        let t = copy.formValues && copy.formValues.tags ? [...copy.formValues.tags] : []
        let tagsString = JSON.stringify(t)
        mediaData[copyFileName].tags = tagsString
        let soc = copy.formValues && copy.formValues.socials ? [...copy.formValues.socials] : []
        let soscialsString = JSON.stringify(soc)
        mediaData[copyFileName].socials = soscialsString
        mediaData[copyFileName].date = new Date(mediaData[copyFileName].date)
      }
      medias.push(f.mediaFile.File)
    })
    if (relatedFiles.selectedFiles && relatedFiles.selectedFiles.length) {
      Array.from(relatedFiles.selectedFiles).forEach(file => data.append('relatedFiles', file));
      data.append('filesComment', filesComment)
    }

    if (collection.questions && collection.questions.length) {
      data.append('questionAnswers', JSON.stringify(questionAnswers))
    }

    Array.from(medias).forEach(file => data.append('mediaFiles', file));
    data.append('mediaData', JSON.stringify(mediaData))
    data.append('isPublic', isPublic.toString())
    data.append('collectionId', collection.id)
    data.append('userId', userContext.loggedInUser.id)
    setSubmitLoading(true)

    try {
      const response = (await Api.uploadContribution(data))
      const contribuitonId = response.data.contributionId
      history.push({ pathname: `/organization/${orgContext?.organization?.id}/collections/${collection.id}/contribution/${contribuitonId}`, state: { collectionData: collection, showContribute: true } })

    } catch (error: any) {
      alert(error)
    }
    finally {
      setSubmitLoading(false)
    }
  }


  const saveFormToMedia = (values: ContributionFormType) => {
    if (!mediaFiles || !selectedMediaItem) {
      return
    }
    let listToUpdate: MediaListItem[] = mediaFiles
    listToUpdate.forEach((file: MediaListItem) => {
      let mediaFile = selectedMediaItem.mediaItem.mediaFile as MediaFile
      if (file.mediaFile.URL === mediaFile.URL) {
        file.formValues = values
      }
    })
    setMediaFiles(listToUpdate)
  }

  const closeRedirectOptionsDialog = (toProfile: boolean) => {
    setShowRedirectOptions(false)
    if (toProfile) {
      history.push(ROUTE.EDITPROFILE + `?organization=${orgContext?.organization?.id}`)
    } else {
      history.push(`/organization/${orgContext?.organization?.id}`)
    }
  }

  const getSelectedMediaUrl = () => {
    if (!selectedMediaItem) return ''
    let mediaFile = selectedMediaItem?.mediaItem.mediaFile as MediaFile
    return mediaFile.URL
  }
  const getOrganizationColor = orgContext && orgContext.organization ? orgContext.organization.color : '#556271'

  if (!collection) {
    return null
  }

  return (
    <div className="App">
      <div className="app-content create-contribution-page">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1>{getTranslation('CREATE_CONTRIBUTION_PAGE.HEADING')} </h1><h1 style={{ fontStyle: 'italic', paddingLeft: 0 }}>{getCollectionKeyInLang(collection, 'title', i18n.language)}</h1>
        </div>
        <div className="contribution-container">
          <ImageUploader
            onChange={(files: MediaListItem[]) => { updateMediaFiles(files) }}
            errorFiles={mediaFilesWithErrors}
            selectedMediaUrl={getSelectedMediaUrl()}
            disableUpload={mediaUploadDisabled}
            translationFunction={getTranslation}
            organizationColor={getOrganizationColor}
          />

        </div>
        <div className="create-contribution-lower">
          <div className="fields-container">
            <UploadProcess
              isEdit={false}
              collection={collection}
              mediaFiles={mediaFiles}
              selectedMediaItem={selectedMediaItem}
              updateSelectediaItem={(newSelectedItem: SelectedItem | null) => setSelectedMediaItem(newSelectedItem)}
              licenses={licences}
              relatedFiles={relatedFiles.selectedFiles}
              filesComment={filesComment}
              termsAndConditionsAccepted={acceptTc}
              organizationColor={getOrganizationColor}
              submitLoading={submitLoading}
              mapScripLoaded={scriptLoaded}
              contributionIsPublic={isPublic}
              renderCollectionQuestions={renderCollectionQuestions}
              updateContributionIsPublic={(contributionIsPublic: boolean) => setIsPublic(contributionIsPublic)}
              onRelatedCommentUpdateHandler={onRelatedCommentUpdateHandler}
              onRelatedFilesInputChangeHandler={onRelatedFilesInputChangeHandler}
              acceptTermsAndConditionsUpdate={(accept: boolean) => setAcceptTc(accept)}
              saveMediaFormValues={saveFormToMedia}
              verifyMediaFormValues={() => {
                if (!mediaFiles) return false
                let filesWithErrors = verifyAllFiles(mediaFiles)
                return filesWithErrors.length === 0
              }}
              disableMediaUpload={(disable: boolean) => setMediaUploadDisabled(disable)}
              adminActions={() => null}
              deleteContribution={() => { }}
              submitContribution={handleSubmit}
            />
          </div>
        </div>
      </div>
      <SimpleDialog
        open={showRedirectOptions}
        title={getTranslation('CREATE_CONTRIBUTION_PAGE.USER_HAS_MISSING_INFORMATION.TITEL')}
        dialogContent={
          (
            <p>{getTranslation('CREATE_CONTRIBUTION_PAGE.USER_HAS_MISSING_INFORMATION.MODAL_CONTENT_TEXT', { orgName: orgContext?.organization?.name })}</p>
          )
        }
        closeText={getTranslation('CREATE_CONTRIBUTION_PAGE.USER_HAS_MISSING_INFORMATION.CLOSE_TEXT')}
        buttonActionText={getTranslation('CREATE_CONTRIBUTION_PAGE.USER_HAS_MISSING_INFORMATION.ACTION_TEXT')}
        cancelFunction={() => closeRedirectOptionsDialog(false)}
        actionFunction={() => closeRedirectOptionsDialog(true)}
        onClickOutSide={() => closeRedirectOptionsDialog(false)}
      />
    </div>
  );
}



export const CreateContribution = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="createContribution" router={history}>
        <CreateContributionComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <CreateContributionComponent {...props} />
  )
}
