import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { CreateAuthString } from '../api/createAuthString'
import { CollectionMediaType } from "../types";

const base_url = process.env.REACT_APP_API_URL || ""

export const useFetch = (page: number, collectionId?: string) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [list, setList] = useState<CollectionMediaType[]>([]);

  const sendQuery = useCallback(async () => {

    try {
      const AuthString = CreateAuthString(true)
      await setLoading(true);
      const res = await axios.get(base_url + '/collection-contributions', { params: { collectionId, paginationId: page }, headers: { Authorization: AuthString } })
      await setList((prev) => [...prev, ...res.data])
      setLoading(false);
    } catch (err) {
      console.error(err)
    }
  }, [page, collectionId]);

  useEffect(() => {
    if (!collectionId) return
    sendQuery();
  }, [sendQuery, page, collectionId]);

  return { loading, list };
}

