import { BsExclamationCircle } from 'react-icons/bs'
import { TextEditor } from '../common/TextEditor'


type CloseCollectionType = {
  updateCloseText: Function
  initialValue: string
  disableEditor: boolean
  translationsFunction: Function
}
export const CloseCollection = ({ updateCloseText, initialValue, disableEditor, translationsFunction }: CloseCollectionType) => {

  return (
    <div className="field-pair">
      <label>{translationsFunction('ADMIN.EDIT_COLLECTION.ARCHIVE_COLLECTION_TEXT')}<BsExclamationCircle style={{ marginLeft: '10px' }} /></label>
      <TextEditor
        disabled={disableEditor}
        initialValue={initialValue}
        onChange={(newValue: string) => { updateCloseText(newValue) }}
      />
    </div>
  )
}