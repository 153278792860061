import React from 'react'
import { KeyBoolean, RolesByOrganizationType, User } from '../types'

export const userHasPermission = (
  userRoles: RolesByOrganizationType[],
  requiredPermission: string,
  organizationId: string,
  isPlatformAdmin: boolean,
): boolean => {

  if (isPlatformAdmin) {
    return true
  }
  if (!userRoles) {
    return false
  }
  let roles: string[] = []
  for (const orgRoles of userRoles) {
    if (orgRoles.organizationId === organizationId) {
      roles = orgRoles.roles
    }
  }

  if (roles.length) {
    return roles.includes(requiredPermission) || roles.includes('SUPER_ADMIN')
  }

  return false
}

interface CanProps {
  userRoles: RolesByOrganizationType[];
  requiredPermission: string;
  organizationId: string,
  isPlatformAdmin: boolean,
  yes: Function;
  no: Function;
}

export const Can = (props: CanProps): React.ReactElement =>
  userHasPermission(props.userRoles, props.requiredPermission, props.organizationId, props.isPlatformAdmin)
    ? props.yes()
    : props.no()

const defaultProps: CanProps = {
  userRoles: [],
  requiredPermission: '',
  organizationId: '',
  isPlatformAdmin: false,
  yes: () => null,
  no: () => null,
}
Can.defaultProps = defaultProps

export const userIsComplete = (user: User, organizationUserFields: KeyBoolean): boolean => {
  let hasAllReguiredInformation = true
  for (const [key, value] of Object.entries(organizationUserFields)) {
    // key is required
    if (value) {

      let userKeyValue
      if (key === 'date_of_birth') {
        userKeyValue = user['dateOfBirth' as keyof User]
      } else if (key === 'place_of_birth') {
        userKeyValue = user['placeOfBirth' as keyof User]
      } else if (key === 'street_address') {
        userKeyValue = user['streetAddress' as keyof User]
      } else {
        userKeyValue = user[key as keyof User]
      }
      if (!userKeyValue) {
        hasAllReguiredInformation = hasAllReguiredInformation && false
      }
    }

  }

  return hasAllReguiredInformation
}