import '../../css/EditCollection.css'
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from 'react-router-dom'
import { CollectionType, OrganizationType } from "../../types"
import { ROUTE } from '../../routes'
import { useUserContext } from '../../context/UserContext';
import { DropDownSelect, OptionType } from '../common/DropDownSelect'
import { Edit } from './Edit'
import { AdminApi } from '../../api/admin';
import { getCollectionTitleOrDescriptionInLang } from '../../helpers/helperFunctions'
import { userHasPermission } from '../../helpers/permissionsHelper'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'


const EditCollectionComponent = () => {
  const location = useLocation()
  const history = useHistory()
  const userCtx = useUserContext()
  const { i18n } = useTranslation()
  const [editableCollections, setEditableCollections] = useState<CollectionType[]>([])
  const [organization, setOrganization] = useState<OrganizationType | null>(null)
  const [selectedCollection, setSelectedCollection] = useState<CollectionType | null>(null)
  const [lockSelectedCollection, setLockSelectedCollection] = useState<boolean>(false)

  const { getTranslation } = useCustomTranslation(organization?.id || '')

  const updateCurrentSelectedCollection = (updatedCollection: CollectionType) => {
    let collectionsList = JSON.parse(JSON.stringify(editableCollections))
    collectionsList.forEach((c: CollectionType) => {
      if (c.id === updatedCollection.id) {
        // Update with new values
        c.isPublic = updatedCollection.isPublic
        c.allowRelatedFiles = updatedCollection.allowRelatedFiles
        c.questions = updatedCollection.questions
        c.imgUrl = updatedCollection.imgUrl
        c.updatedAt = updatedCollection.updatedAt
        c.translations = updatedCollection.translations
        c.hasTerms = updatedCollection.hasTerms
        c.contributionFields = updatedCollection.contributionFields
        c.status = updatedCollection.status
        c.archivedText = updatedCollection.archivedText
      }
    });

    setEditableCollections(collectionsList)
    setLockSelectedCollection(false)
    setSelectedCollection(null)
  }


  const deletedCollection = (collectionId: string) => {
    let collectionsList = JSON.parse(JSON.stringify(editableCollections))
    let newEditableCollections = collectionsList.filter((collection: CollectionType) => collection.id !== collectionId)
    setEditableCollections(newEditableCollections)
    setLockSelectedCollection(false)
    setSelectedCollection(null)

  }

  useEffect(() => {

    const getAllOrganizationCollections = async (organizationId: string) => {
      try {
        const response = (await AdminApi.getAllCollections(organizationId)).data
        setEditableCollections(response)

      } catch (error: any) {
        console.error(error)
      }
    }

    if (!location.state || !userCtx || !userCtx.loggedInUser) {
      history.push(ROUTE.HOME)
    } else {
      const { org }: any = location.state
      const userRoles = userCtx.loggedInUser.roles

      if (!(userHasPermission(userRoles, userCtx.actionRoles.SUPER_ADMIN, org.id, userCtx.userIsPlaformAdmin) || userHasPermission(userRoles, userCtx.actionRoles.ADMIN, org.id, userCtx.userIsPlaformAdmin))) {
        history.push(ROUTE.HOME)
      }
      setOrganization(org)
      getAllOrganizationCollections(org.id)
    }

  }, [location, history, userCtx])


  const formatCollectionsForDropDown = () => {
    return editableCollections.map((c: CollectionType) => {
      return { value: c.id, label: getCollectionTitleOrDescriptionInLang(c, 'title', i18n.language) }
    })
  }

  const selectCollection = (collectionId: string) => {
    let filtered = editableCollections.filter(c => c.id === collectionId)
    let collection = filtered.length === 1 ? filtered[0] : null
    if (collection) {
      setSelectedCollection(collection)
    }
  }

  if (!organization) {
    return (<div className="loader--loading"></div>)
  }
  return (
    <div className="App">
      <div className="app-content edit-collection-page">
        <h1>{organization?.name}</h1>
        <h2>{getTranslation('ADMIN.EDIT_COLLECTION.HEADING')}</h2>
        <div className="edit-collection-container">
          <DropDownSelect
            title={getTranslation('ADMIN.EDIT_COLLECTION.DROP_DOWN_SELECT')}
            options={formatCollectionsForDropDown()}
            onUpdate={(opt: OptionType) => selectCollection(opt.value)}
            selectedValue={selectedCollection ? getCollectionTitleOrDescriptionInLang(selectedCollection, 'title', i18n.language) : null} closeOnSelect={true}
            disabled={lockSelectedCollection} />
          <Edit
            collection={selectedCollection}
            organizationId={organization.id}
            organizationColor={organization.color}
            updateState={(value: boolean) => setLockSelectedCollection(value)}
            updateSuccess={(updatedCollection: CollectionType) => updateCurrentSelectedCollection(updatedCollection)}
            collectionDeleted={(collectionId: string) => deletedCollection(collectionId)} />

        </div>
      </div>

    </div>
  )
}

export const EditCollection = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="createCollection" router={history}>
        <EditCollectionComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <EditCollectionComponent {...props} />
  )
}