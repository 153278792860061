import '../../css/Header.css'
import { useState, useEffect } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useApplicationContext } from '../../context/ApplicationContext';

const availableLanguages = {
  sv: 'Svenska',
  en: 'English',
  dk: 'Dansk',
  fi: 'Suomi'
}

type LanguageSelectorProps = {
  customClasses?: string
  onLanguageUpdate: Function
  customLang?: string
}
export const LanguageSelector = ({ customClasses, onLanguageUpdate, customLang }: LanguageSelectorProps) => {
  const appCtx = useApplicationContext()
  const [currentLanguage, setCurentLanguage] = useState<string>(customLang || appCtx.language)

  const changeLanguage = (lang: string) => {
    onLanguageUpdate(lang)
    setCurentLanguage(lang)
  }

  const options = () => {
    const langs = []
    for (const [key, value] of Object.entries(availableLanguages)) {
      langs.push([key, value])
    }

    return langs.map((l: string[]) => (
      <DropdownMenu.Item className="lang-select-item" onSelect={() => changeLanguage(l[0])} key={l[0]}>
        {l[1]}
      </DropdownMenu.Item>
    ))
  }

  useEffect(() => {
    let lang = appCtx.language
    if (!customLang && lang !== currentLanguage) {
      setCurentLanguage(lang)
    }
  }, [appCtx.language, currentLanguage, customLang])

  useEffect(() => {

    if (customLang && customLang !== currentLanguage) {
      setCurentLanguage(customLang)
    }
  }, [customLang, currentLanguage])


  const getSelectedStyle = currentLanguage === 'fi' ? { padding: '10px 8px 10px 8px' } : { padding: '10px 5px 10px 5px' }
  const classes = customClasses ? customClasses : 'lang-select-trigger'

  return (
    <div>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger className={classes}>
          <div style={getSelectedStyle}>
            {currentLanguage}
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className="lang-select-content">
          {options()}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  )
}