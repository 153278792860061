import '../../../css/CreateContribution.css';
import '../../../css/MediaForm.css';
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import {
  CollectionType,
  LicenseType,
  MediaListItem,
  SelectedItem,
  EditFileType,
  ImageType,
  ContributionFormType
} from '../../../types'
import { _handleKeyDownPreventDefault, getCollectionKeyInLang } from '../../../helpers/helperFunctions'
import { getInitialMediaFormValuesForm, getFilledValueEditFileType } from '../../../helpers/mediaFileFunctions'
import { CustomCheckbox, SimpleCheckbox } from '../../common/CustomCheckbox'
import { SimpleDialog } from '../../common/SimpleDialog'
import { RelatedFiles } from './ReleatedFiles';
import { MediaForm } from './MediaForm'
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import { useCustomTranslation } from '../../../helpers/CustomTranslationsComps'

type UploadProcessProps = {
  isEdit: boolean
  initalStep?: number
  collection: CollectionType
  mediaFiles: MediaListItem[] | EditFileType[] | null
  selectedMediaItem: SelectedItem | null
  updateSelectediaItem: Function
  licenses: LicenseType[]
  relatedFiles: FileList | null
  filesComment: string
  renderRelatedFilesForEdit?: Function
  termsAndConditionsAccepted: boolean
  organizationColor: string
  submitLoading: boolean
  mapScripLoaded: boolean
  contributionIsPublic: boolean
  renderCollectionQuestions: Function
  updateContributionIsPublic: Function
  onRelatedCommentUpdateHandler: Function
  onRelatedFilesInputChangeHandler: Function
  acceptTermsAndConditionsUpdate: Function
  saveMediaFormValues: Function
  verifyMediaFormValues: Function
  disableMediaUpload: Function
  adminActions: Function
  deleteContribution: Function
  submitContribution: Function

}

const filerBasedOnType = (list: MediaListItem[] | EditFileType[], indexToFind: number) => {

  if ('type' in list[0]) {
    return (list as EditFileType[]).filter((media: EditFileType, index: number) => index === indexToFind)[0]
  }

  return (list as MediaListItem[]).filter((media: MediaListItem, index: number) => index === indexToFind)[0]
}

const findItemWithLowerIndex = (list: MediaListItem[] | EditFileType[], indexToFind: number) => {
  if ('type' in list[0]) {
    return (list as EditFileType[]).filter((media: EditFileType, index: number) => (index + 1) === indexToFind)
  }

  return (list as MediaListItem[]).filter((media: MediaListItem, index: number) => (index + 1) === indexToFind)
}


export const UploadProcess = ({
  isEdit,
  initalStep,
  collection,
  mediaFiles,
  selectedMediaItem,
  updateSelectediaItem,
  licenses,
  organizationColor,
  relatedFiles,
  filesComment,
  renderRelatedFilesForEdit,
  termsAndConditionsAccepted,
  submitLoading,
  mapScripLoaded,
  contributionIsPublic,
  renderCollectionQuestions,
  updateContributionIsPublic,
  onRelatedCommentUpdateHandler,
  onRelatedFilesInputChangeHandler,
  acceptTermsAndConditionsUpdate,
  saveMediaFormValues,
  verifyMediaFormValues,
  disableMediaUpload,
  adminActions,
  deleteContribution,
  submitContribution }: UploadProcessProps) => {
  const { i18n } = useTranslation()
  let history = useHistory();

  const [openReadTerms, setOpenReadTerms] = useState<boolean>(false)
  const [allowMoveToNextMedia, setAllowMoveToNextMedia] = useState<boolean>(false)
  const [processStep, setProcessStep] = useState<number>(1)

  const { getTranslation } = useCustomTranslation(collection.organizationId)

  const stepInProccess = (stepToTake: number) => {
    let newStep = processStep + stepToTake
    if (newStep === 1) {
      disableMediaUpload(false)
    } else {
      disableMediaUpload(true)
    }
    if (newStep === 2 && mediaFiles) {
      updateSelectediaItem({ mediaItem: mediaFiles[0], itemIndx: 0 })
      disableMediaUpload(true)
    } else {
      updateSelectediaItem(null)
    }
    setProcessStep(newStep)
  }

  const stepInMedia = (stepDirecition: number) => {
    if (!selectedMediaItem || !mediaFiles) return

    if (selectedMediaItem.itemIndx === 0 && stepDirecition < 0) return

    if (selectedMediaItem.itemIndx === (mediaFiles.length - 1) && stepDirecition > 0) return

    let newIndex = selectedMediaItem.itemIndx + stepDirecition

    let newSelectedMedia = filerBasedOnType(mediaFiles, newIndex)
    updateSelectediaItem({ mediaItem: newSelectedMedia, itemIndx: newIndex })
  }

  const getPrevMediaValues = () => {
    if (!mediaFiles || !selectedMediaItem || selectedMediaItem.itemIndx === 0) return {}

    let mediaItemList = findItemWithLowerIndex(mediaFiles, selectedMediaItem.itemIndx)
    if (mediaItemList.length) {
      let item = mediaItemList[0]
      if ('type' in item) {
        let initialFrom = getInitialMediaFormValuesForm(collection.contributionFields)
        const keys = Object.keys(initialFrom)
        for (const k of keys) {
          let field = k as keyof ContributionFormType
          let initialFieldValue = getFilledValueEditFileType(field as keyof ImageType, item as EditFileType)
          initialFrom[field] = initialFieldValue
        }

        return initialFrom
      } else {

        return item.formValues
      }
    }
    return {}
  }

  const renderAcceptCollectionTerms = () => {
    if (!collection) return null
    let collectionTerms = getCollectionKeyInLang(collection, 'terms', i18n.language)
    if (!collectionTerms) return null
    return (
      <div className="read-collection-terms-container">
        <SimpleCheckbox value={termsAndConditionsAccepted} onUpdate={(value: boolean) => acceptTermsAndConditionsUpdate(value)} />
        <div className="read-collection-terms-text" onClick={() => setOpenReadTerms(true)}>
          {getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_ACCEPT_COLLECTION_TERMS')}
        </div>
        <SimpleDialog
          open={openReadTerms}
          title={""}
          dialogContent={
            (
              <div dangerouslySetInnerHTML={{ __html: collectionTerms }} />
            )
          }
          closeText={getTranslation('GLOBAL.OKEJ')}
          buttonActionText={""}
          cancelFunction={() => setOpenReadTerms(false)}
          actionFunction={() => setOpenReadTerms(false)}
          onClickOutSide={() => setOpenReadTerms(false)}
          organizationColor={organizationColor}
          showAction={false}
        />
      </div>
    )
  }

  const renderStep = () => {
    if (processStep === 1) {
      return (
        <>
          <div style={{ marginBottom: 20, height: 300 }}>
            <button className="btn" style={{ backgroundColor: organizationColor, width: 250 }} onClick={() => {
              stepInProccess(1)
            }} disabled={!mediaFiles}>2. {getTranslation('CREATE_CONTRIBUTION_PAGE.ADD_MEDIA_INFO_BUTTON')}</button>
          </div>
          <button className="btn" style={{ backgroundColor: organizationColor, width: 250 }} onClick={() => {
            history.goBack()
          }}>{getTranslation('GLOBAL.CANCEL')}</button>
        </>
      )
    }

    if (processStep === 2) {
      if (!Object.keys(collection.contributionFields).length) {
        return (
          <>
            <h3>{getTranslation('CREATE_CONTRIBUTION_PAGE.NO_MEDIA_FIELDS_TO_FILL_IN')}</h3>
            <button
              className="btn" style={{ backgroundColor: organizationColor }}
              onClick={() => {
                stepInProccess(1)
              }}>
              {getTranslation('CREATE_CONTRIBUTION_PAGE.IMAGE_SELECTION_FORWARD_ARROW')}
            </button>
          </>
        )
      }
      const cursorStyleNextArrow = allowMoveToNextMedia && mediaFiles && selectedMediaItem && (mediaFiles.length - 1) > selectedMediaItem.itemIndx ? 'pointer' : 'not-allowed'
      const cursorStylePrevArrow = selectedMediaItem && selectedMediaItem.itemIndx > 0 ? 'pointer' : 'not-allowed'
      const arrowColorNext = allowMoveToNextMedia && mediaFiles && selectedMediaItem && (mediaFiles.length - 1) > selectedMediaItem.itemIndx ? 'black' : 'grey'
      const arrowColorPrev = selectedMediaItem && selectedMediaItem.itemIndx > 0 ? 'black' : 'grey'
      let canMoveToNextStep = false
      if (mediaFiles) {
        if (verifyMediaFormValues(mediaFiles)) {
          canMoveToNextStep = true
        }
      }
      return (
        <>
          {mediaFiles?.length === 1 ? null : (
            <div className="upload-process-media-arrows">
              <div style={{ display: 'flex', alignItems: 'center', cursor: cursorStylePrevArrow, }} onClick={() => { stepInMedia(-1) }}>
                <MdArrowBackIos size="2rem" color={arrowColorPrev} />
                <div style={{ color: arrowColorPrev }}>{getTranslation('CREATE_CONTRIBUTION_PAGE.IMAGE_SELECTION_BACK_ARROW')}</div>
              </div>
              <div style={{ display: 'flex', cursor: cursorStyleNextArrow, alignItems: 'center' }} onClick={() => {
                if (allowMoveToNextMedia) {
                  stepInMedia(1)
                }
              }}>
                <div style={{ color: arrowColorNext }}>{getTranslation('CREATE_CONTRIBUTION_PAGE.IMAGE_SELECTION_FORWARD_ARROW')}</div>
                <MdArrowForwardIos size="2rem" color={arrowColorNext} />
              </div>
            </div>
          )}
          <div>
            <>
              <MediaForm
                selectedMedia={selectedMediaItem!.mediaItem}
                onFormSave={saveMediaFormValues}
                licences={licenses}
                mapIsLoaded={mapScripLoaded}
                organizationColor={organizationColor}
                organizationId={collection.organizationId}
                contributionFields={collection.contributionFields}
                isAllowedToMoveToNextStep={(formIsValid: boolean) => setAllowMoveToNextMedia(formIsValid)}
                isFirst={selectedMediaItem?.itemIndx === 0}
                getPrevMediaFormValues={getPrevMediaValues}
                translationFunction={getTranslation}

              />
            </>
          </div>
          <div style={{ padding: 20 }}><span>{getTranslation('CREATE_CONTRIBUTION_PAGE.ADD_INFO_TO_ALL_FOR_NEXT_STEP')}</span></div>
          <div className="flex">
            <button
              className="btn" style={{ backgroundColor: organizationColor }}
              disabled={processStep === initalStep}
              onClick={() => {
                stepInProccess(-1)
              }}>
              {getTranslation('CREATE_CONTRIBUTION_PAGE.STEP_PREVIOUS')}
            </button>
            <button
              className="btn" style={{ backgroundColor: organizationColor }}
              disabled={!canMoveToNextStep}
              onClick={() => {
                stepInProccess(1)
              }}>
              {getTranslation('CREATE_CONTRIBUTION_PAGE.STEP_NEXT')}
            </button>
          </div>
        </>

      )

    }
    if (processStep === 3) {
      const submitButtonClasses = submitLoading ? 'loader-btn loader-btn--loading btn-stnd-al' : 'loader-btn btn-stnd-al'
      if (isEdit) {
        return (
          <>
            <h3>{getTranslation('CREATE_CONTRIBUTION_PAGE.ADMIN_SELECTED_EXTRA_STEP_TITLE')}</h3>
            <form onSubmit={e => {
              e.preventDefault()
            }} name="contributionForm">
              <div className="public-contrib-checkbox-wrapper">
                <CustomCheckbox text={getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_IS_PUBLIC')} value={contributionIsPublic} onUpdate={(newValue: boolean) => updateContributionIsPublic(newValue)} />
              </div>
            </form>
            {collection.allowRelatedFiles && renderRelatedFilesForEdit ?
              (<>{renderRelatedFilesForEdit()}</>)
              : null}

            <div className="flex" id="edit-ctb-last-step-btns">
              <button
                className="btn" style={{ backgroundColor: organizationColor }}
                onClick={() => {
                  stepInProccess(-1)
                }}>
                {getTranslation('CREATE_CONTRIBUTION_PAGE.STEP_PREVIOUS')}
              </button>
              <button className={submitButtonClasses} onClick={() => submitContribution()} disabled={!mediaFiles || mediaFiles.length === 0} style={{ margin: '15px', backgroundColor: organizationColor }}>
                <span className="loader-btn__text">{getTranslation('CREATE_CONTRIBUTION_PAGE.SUBMIT_BUTTON')}</span>
              </button>
              <ConfirmationDialog
                title={getTranslation('EDIT_CONTRIBUTION.DELETE.TITLE')}
                subTitle={getTranslation('EDIT_CONTRIBUTION.DELETE.SUB_TITLE')}
                buttonConfirmText={getTranslation('EDIT_CONTRIBUTION.DELETE.CONFIRM_BUTTON')}
                checkboxText={getTranslation('EDIT_CONTRIBUTION.DELETE.CHECKBOX_TEXT')}
                triggerButtonText={getTranslation('EDIT_CONTRIBUTION.DELETE.DELETE_BUTTON')}
                onConfirmClick={deleteContribution}
                translationFunction={getTranslation}
                danger

              />
            </div>

            {adminActions()}

          </>
        )
      }
      return (
        <>
          <h3>{getTranslation('CREATE_CONTRIBUTION_PAGE.ADMIN_SELECTED_EXTRA_STEP_TITLE')}</h3>
          <form onSubmit={e => {
            e.preventDefault()
          }} name="contributionForm">
            <div className="public-contrib-checkbox-wrapper">
              <CustomCheckbox text={getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_IS_PUBLIC')} value={contributionIsPublic} onUpdate={(newValue: boolean) => updateContributionIsPublic(newValue)} />
            </div>
            {collection.hasTerms ? renderAcceptCollectionTerms() : null}
            {renderCollectionQuestions()}
            {collection.allowRelatedFiles && (
              <RelatedFiles
                title={getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_RELATED_FILES')}
                subText={getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_RELATED_FILES_SUB')}
                commentTitle={getTranslation('CREATE_CONTRIBUTION_PAGE.FIELD_RELATED_FILES_COMMENT')}
                onCommentUpdate={onRelatedCommentUpdateHandler}
                onUpdate={onRelatedFilesInputChangeHandler}
                handleKeyDown={_handleKeyDownPreventDefault}
                value={relatedFiles}
                commentValue={filesComment}
              />)}
          </form>
          <div className="flex">
            <button
              className="btn" style={{ backgroundColor: organizationColor }}
              onClick={() => {
                stepInProccess(-1)
              }}>
              {getTranslation('CREATE_CONTRIBUTION_PAGE.STEP_PREVIOUS')}
            </button>
            <button className={submitButtonClasses} onClick={() => submitContribution()} disabled={!mediaFiles || mediaFiles.length === 0} style={{ margin: '15px', backgroundColor: organizationColor }}>
              <span className="loader-btn__text">{getTranslation('CREATE_CONTRIBUTION_PAGE.SUBMIT_BUTTON')}</span>
            </button>
          </div>
        </>
      )
    }
  }

  useEffect(() => {
    if (initalStep) {
      setProcessStep(initalStep)
    }
  }, [initalStep])

  return (
    <div className="upload-process">
      {renderStep()}
    </div>
  )

}