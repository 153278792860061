import '../../css/CreateCollection.css';
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { GrFormAdd } from 'react-icons/gr'
import {
  SingleFileUpLoadState,
  Event,
  Language,
  LanguagesInputs,
  Group,
  CollectionTranslation,
  DynamicField,
  KeyBoolean
} from '../../types'
import { useOrganizationContext } from '../../context/OrganizationContext';
import { useUserContext } from '../../context/UserContext';
import { CustomCheckbox } from '../common/CustomCheckbox'
import { AdminApi } from '../../api/admin'
import { ShowQuestions } from './ShowQuestions'
import { AddQuestions } from './AddQuestions'
import { CollectionTranslations } from './CollectionTranslations'
import { toastNotifyError } from '../common/ToastMessage'
import { ROUTE } from '../../routes'
import { DynamicFieldsPicker, SelectedField, PickerOptionType } from './DynamicFieldsPicker'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'


type NewCollectionType = {
  isPublic: boolean
  groupName: string
  allowRelatedFiles: boolean
  questions: string[]
  hasTerms: boolean
}



const CreateCollectionComponent = () => {
  const history = useHistory()
  const orgContext = useOrganizationContext()
  const userContext = useUserContext()
  const [collectionForm, setCollectionForm] = useState<NewCollectionType>({
    isPublic: true,
    groupName: "",
    allowRelatedFiles: true,
    questions: [],
    hasTerms: false,
  })
  const [imageFile, setImageFile] = useState<SingleFileUpLoadState>({
    selectedFile: null,
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [showQustionInput, setShowQuestionInput] = useState<boolean>(false)
  const [languages, setLanguages] = useState<LanguagesInputs | null>(null)
  const [contributionFields, setContributionFields] = useState<DynamicField[] | null>(null)
  const [selectedContributionFields, setSelectedContributionsFields] = useState<SelectedField[]>([])
  const [groups, setGroups] = useState<(Group[])>([])
  const [orgId, setOrgId] = useState<string>("")

  const { getTranslation } = useCustomTranslation(orgId)

  const handleTranslationUpdate = (language: string, key: 'title' | 'description' | 'terms', value: string) => {
    if (languages && language in languages) {
      let prevLangValues = languages[language]
      prevLangValues[key] = value
      setLanguages(prev => ({ ...prev, [language]: prevLangValues }))
    }
  }

  const onChangeHandler = (event: Event<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    if (file != null) {
      setImageFile({ selectedFile: file })
    }
  }

  const deleteQuestion = (question: string) => {
    let filtered = (collectionForm.questions.filter(q => q.toLowerCase() !== question.toLowerCase())) as string[]
    setCollectionForm(prev => ({ ...prev, questions: filtered }))
  }

  const termsFieldIsConsideredEmpty = (terms: string) => {
    let isEmpty = false
    switch (terms) {
      case '':
      case '<h1><br></h1>':
      case '<h2><br></h2>':
      case '<h3><br></h3>':
      case '<p><br></p>':
        isEmpty = true
        break;
      default:
        isEmpty = false
    }
    return isEmpty
  }

  const validateForm = () => {
    if (!languages) return false
    let entries = Object.entries(languages)
    let isValid = entries.reduce((acc, current) => {
      let needsTerms = collectionForm.hasTerms ? current[1].terms != null && !termsFieldIsConsideredEmpty(current[1].terms) : true
      if (current[1].required) {
        return acc && (current[1].title.length > 0 && current[1].description.length > 0 && needsTerms)
      } else if (current[1].title !== '' || current[1].description !== '') {
        return acc && needsTerms
      }
      return acc && true
    }, true)
    return isValid
  }


  const submissionFormatTranslations = () => {
    if (!languages) return ""
    let formatted: any = []
    for (const [key, value] of Object.entries(languages)) {
      if (value.title === '' || value.description === '') {
        continue
      }

      let langObject: CollectionTranslation = {
        languageCode: key,
        title: value.title,
        description: value.description,
      }

      if (collectionForm.hasTerms) {
        langObject.terms = value.terms
      }
      formatted.push(langObject)
    }
    return formatted
  }

  const formatContributionFields = () => {
    let cfields: KeyBoolean = {}
    selectedContributionFields.forEach((f: SelectedField) => {
      const entries = Object.entries(f)
      let fieldId = entries[0][1]
      cfields[fieldId as string] = entries[2][1] as boolean
    })

    return cfields
  }

  const handleSubmit = async () => {

    let formattedContributionFields = formatContributionFields()

    if (!validateForm()) {
      toastNotifyError(getTranslation('ADMIN.MISSING_TRANSLATIONS_ERROR'))
      return
    }
    if (!imageFile.selectedFile) {
      toastNotifyError(getTranslation('ADMIN.MISSING_COLLECTION_IMAGE_ERROR'))
      return
    }


    if (!orgContext?.organization || !userContext?.loggedInUser) {
      return
    }

    const data = new FormData()
    data.append('organizationId', orgContext.organization?.id)
    const formattedTranslations = submissionFormatTranslations()
    data.append('translations', JSON.stringify(formattedTranslations))
    data.append('isPublic', collectionForm.isPublic.toString())
    data.append('groupName', collectionForm.groupName)
    data.append('userId', userContext.loggedInUser.id)
    data.append('photo', imageFile.selectedFile)
    data.append('allowRelatedFiles', collectionForm.allowRelatedFiles.toString())
    data.append('hasTerms', collectionForm.hasTerms.toString())
    data.append('contributionFields', JSON.stringify(formattedContributionFields))
    if (collectionForm.questions.length) {
      data.append('questions', JSON.stringify(collectionForm.questions))
    }

    setIsSubmitting(true)

    try {
      const status = (await AdminApi.uploadCollection(data))
      if (status === 201) {
        history.push(`/organization/${orgContext.organization.id}`)
      }

    } catch (error: any) {
      toastNotifyError(error.response ? error.response.data.message : 'Something went wrong, could not create collection')
    }
    finally {
      setIsSubmitting(false)
    }

  }
  const previewImage = () => {
    if (!imageFile.selectedFile) {
      return null
    }
    return <img className="" src={URL.createObjectURL(imageFile.selectedFile)} alt="" />
  }
  const updateQuestions = (question: string) => {
    const prevQuestions = collectionForm.questions ? [...collectionForm.questions, question] : [question]
    setCollectionForm(prev => ({ ...prev, questions: prevQuestions }))
  }

  useEffect(() => {
    const getAvaialbleCollectionFields = async (organizationId: string) => {
      const response = await AdminApi.getContributionFields(organizationId)
      setContributionFields(response)
    }
    const getGroups = async (orgId: string) => {
      try {
        const response = await AdminApi.getGroups(orgId)
        setGroups(response.data)
      } catch (error: any) {
        console.error(error)
      }
    }

    if (!orgContext || !orgContext.organization) {
      history.push(ROUTE.HOME)
    }

    getGroups(orgContext!.organization!.id)
    setOrgId(orgContext!.organization!.id)


    const getLanguages = async () => {
      const response = await AdminApi.getLanguages()
      let x: LanguagesInputs = {}
      response.forEach((l: Language) => {
        x[l.language] = {
          required: l.required,
          language: l.language,
          title: '',
          description: '',
          terms: ''
        }
      });
      setLanguages(x)
    }
    if (!languages) {
      getLanguages()
    }
    if (!contributionFields) {
      getAvaialbleCollectionFields(orgContext!.organization!.id)
    }
  }, [languages, setLanguages, orgContext, setContributionFields, contributionFields, history, setOrgId])

  const submitButtonClasses = isSubmitting ? 'loader-btn loader-btn--loading btn-stnd-al m-t-50' : 'loader-btn btn-stnd-al m-t-50'
  if (!languages) {
    return <div className="loader--loading" style={{ height: '100vh' }}></div>
  }

  return (
    <div className="App">
      <div className="app-content create-collection-page">
        <h1>{orgContext?.organization?.name}</h1>
        <h2>{getTranslation('ADMIN.CREATE_COLLECTION')}</h2>
        <div className="create-collection-container">
          <div className="image-selection-container">
            <div className="preview-collection-image">
              {previewImage()}
            </div>
            <label className="image-input">
              <input type="file" id="collectionImage" name="collectionImage" accept="image/*" onChange={onChangeHandler} />
            </label>
          </div>

          <div className="collection-group">
            <CustomCheckbox
              text={getTranslation('ADMIN.FIELD_IS_PUBLIC')}
              value={collectionForm.isPublic}
              onUpdate={() => {
                let currentValue = collectionForm.isPublic
                setCollectionForm(prev => ({ ...prev, isPublic: !currentValue }))
              }}
            />
            <CustomCheckbox
              text={getTranslation('ADMIN.FIELD_HAS_TERMS')}
              value={collectionForm.hasTerms}
              onUpdate={() => {
                let currentValue = collectionForm.hasTerms
                setCollectionForm(prev => ({ ...prev, hasTerms: !currentValue }))
              }}
            />
            {!collectionForm.isPublic ?
              <select id="groups" value={collectionForm.groupName} size={1} onChange={(e) => {
                let { value } = e.target;
                setCollectionForm(prev => ({ ...prev, groupName: value }))
              }} disabled={!groups || groups.length === 0}>
                {groups.length > 0 ? <option value='default' hidden> -- {getTranslation('GROUPS.SELECT_GROUP')} -- </option>
                  : <option value='_no_group'> -- {getTranslation('GROUPS.NO_GROUPS')} -- </option>}
                <option value='_no_group'> -- {getTranslation('GROUPS.NO_GROUP')} -- </option>
                {groups.map((group, index) => {
                  return (<option key={group.name} value={group.name} >{group?.name}</option>)
                })}
              </select> : ""}
          </div>
          <CollectionTranslations
            translationsFunction={getTranslation}
            languages={languages}
            onTranslationUpdate={handleTranslationUpdate}
            hasTerms={collectionForm.hasTerms}
          />

          <hr />
          <h3>{getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.HEADER')}</h3>
          <div className="contribution-settings-container">
            <div className="settings-wrapper">
              <CustomCheckbox
                text={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.ALLOW_RELATED_FILES')}
                value={collectionForm.allowRelatedFiles}
                onUpdate={() => {
                  let currentValue = collectionForm.allowRelatedFiles
                  setCollectionForm(prev => ({ ...prev, allowRelatedFiles: !currentValue }))
                }}
              />
              <DynamicFieldsPicker
                title={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.CONTRIBUTION_MEDIA_FIELDS')}
                fields={contributionFields || []}
                removeField={(fieldId: string) => {
                  let filtered = selectedContributionFields.filter(f => f.id !== fieldId)
                  setSelectedContributionsFields(filtered)
                }}
                onUpdate={(option: PickerOptionType) => {
                  let n = {
                    id: option.id,
                    name: option.name,
                    required: false
                  }
                  setSelectedContributionsFields(prev => ([...prev, n]))

                }}
                currentFields={selectedContributionFields}
              />
              <div style={{ padding: '10px', width: '300px' }}>
                {selectedContributionFields.map((f: SelectedField) => {
                  return (
                    <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr 1fr' }}>
                      <div style={{ marginRight: '10px' }}>{f.name}</div>
                      <CustomCheckbox
                        text={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.REQUIRED_FIELD')}
                        value={f.required}
                        onUpdate={() => {
                          f.required = !f.required
                          setSelectedContributionsFields([...selectedContributionFields])
                        }}
                      />
                    </div>
                  )
                })}
              </div>
              <div className="flex">
                <p>{getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.CREATE_QUESTION_HEADER')}</p>
                <GrFormAdd size="2em" onClick={() => setShowQuestionInput(!showQustionInput)} />
              </div>
              {showQustionInput && (
                <AddQuestions
                  title={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.CREATE_QUESTION_TITLE')}
                  addQuestion={updateQuestions}
                  buttonColor={`${orgContext?.organization?.color}`}
                  addButtonText={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.CREATE_QUESTION_ADD_BUTTON')}
                />
              )}
              <ShowQuestions
                title={getTranslation('ADMIN.CREATE_COLLECTION_SETTINGS.ADDED_QUESTIONS')}
                questions={collectionForm.questions}
                deleteQuestion={deleteQuestion}
                buttonColor={`${orgContext?.organization?.color}`}
              />
            </div>
          </div>

          <button className={submitButtonClasses} style={{ backgroundColor: `${orgContext?.organization?.color}` }} onClick={handleSubmit} disabled={false}>
            <span className="loader-btn__text">{getTranslation('ADMIN.SUBMIT_COLLECTION_BUTTON')}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export const CreateCollection = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="createCollection" router={history}>
        <CreateCollectionComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <CreateCollectionComponent {...props} />
  )
}


