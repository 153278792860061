
import '../../css/App.css';
import { useState, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'

import { DynamicField } from '../../types'


export type PickerProps = {
  title: string
  fields: DynamicField[]
  removeField: Function
  onUpdate: Function
  currentFields: SelectedField[]

}

export type PickerOptionType = {
  id: string
  name: string
}

export type SelectedField = {
  id: string
  name: string
  required: boolean
}

export const getFieldInLang = (field: DynamicField, lang: string) => {
  if (lang in field) {
    return field[lang as keyof DynamicField]
  }

  return field['en']
}

export const DynamicFieldsPicker = ({ title, fields, removeField, onUpdate, currentFields }: PickerProps) => {
  const { i18n } = useTranslation()
  const [options, setOptions] = useState<Array<PickerOptionType>>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const renderOptions = () => {

    return (
      options.map((opt: PickerOptionType) => (
        <div
          className="dd-list-item-test"
          key={opt.id}
          onClick={() => {
            onUpdate(opt)
          }}
        >
          {opt.name}
          {' '}
          {currentFields.some((i: SelectedField) => i.id === opt.id) && (
            <span >
              <AiOutlineCheck color="#2b6777" />
            </span>
          )}
        </div>
      ))

    )
  }

  const handleItemClick = (e: any, s: string) => {
    if (!e) {
      e = window.event;
    }
    if (e) {
      e.cancelBubble = true;
      if (e.stopPropagation) {
        e.stopPropagation()
      };
    }

    removeField(s)
  }

  useEffect(() => {

    if (!options.length) {
      let o: PickerOptionType[] = []
      o = fields.map((field: DynamicField) => {
        return { id: field.field, name: getFieldInLang(field, i18n.language) }
      })

      setOptions(o)
    }
  }, [fields, i18n, options])

  return (
    <div className="field-pair" >
      <label>{title}</label>
      <div
        className="wrapper"
      >
        <button
          type="button"
          className="header-test"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <div
            className="header-title-test"
          >
            {currentFields.length > 0 ?
              (<div className="options">
                {currentFields.map((s: SelectedField) => (
                  <div className="option" key={s.name} onClick={(e: any) => handleItemClick(e, s.id)}>
                    <div className="option-text">{s.name}</div>

                    <AiOutlineClose size="1em" />

                  </div>
                ))}
              </div>
              ) : null}
          </div>
          <div style={{ lineHeight: '4px' }}>
            {isOpen
              ? <IoIosArrowUp size="2em" />
              : <IoIosArrowDown size="2em" />
            }
          </div>
        </button>
        {isOpen && (
          <div
            role="list"
            className="dd-list-test"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="dd-scroll-list-test"
            >
              {renderOptions()}
            </div>
          </div>
        )}
        <div>
        </div>

      </div>
    </div>
  )
}
