import "../../css/Tagged.css";

import { useHistory, useLocation } from "react-router-dom";
import { useOrganizationContext } from "../../context/OrganizationContext";
import ErrorBoundary from "../../errorHandlers/ErrorBoundry";
import { useEffect, useState } from "react";
import { Api } from "../../api/api";

type TaggedImage = {
  id: string;
  imageid: string;
  category: string;
  thumbnail_url: string;
  imageurl: string;
  createdAt: string;
  userid: string;
};

// Id           string `json:"id" db:"id"`
// ImageId      string `json:"image_id" db:"image_id"`
// Category     string `json:"image_category" db:"image_category"`
// ThumbnailUrl string `json:"thumbnail_url" db:"thumbnail_url"`
// ImageUrl     string `json:"imageurl" db:"imageurl"`
// CreatedAt    string `json:"created_at" db:"created_at"`
// UserId       string `json:"user_id" db:"user_id"`

export const TaggedComponent = () => {
  let history = useHistory();
  const location = useLocation();
  const orgCtx = useOrganizationContext();

  const [images, setImages] = useState<TaggedImage[] | null>(null);

  const test = [1, 2, 3];
  const getImages = async () => {
    const imageResp = await Api.getTaggedImages();
    console.log(imageResp);
    setImages(imageResp);
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <div className="tagged-images-page">
      <h1 className="tagged-images-title">Favoriter</h1>
      <div className="tagged-images-container">
        {images && images[0].category}
        {images &&
          images.map((image) => {
            return (
              <div id={image.id} key={image.id} className={"tagged-img-wrapper"}>
                <img className="tagged-img" src={image.thumbnail_url || ""} alt={"tagged image"} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const Tagged = ({ ...props }) => {
  const history = useHistory();
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="Collection" router={history}>
        <TaggedComponent {...props} />
      </ErrorBoundary>
    );
  }
  return <TaggedComponent {...props} />;
};
