import * as Checkbox from '@radix-ui/react-checkbox';
import { AiOutlineCheck } from 'react-icons/ai'
import { IoCheckmarkCircleSharp } from 'react-icons/io5'
type CustomCheckboxProps = {
  text: any
  onUpdate: Function
  value: boolean
  isDisabled?: boolean
}

export const CustomCheckbox = ({ text, onUpdate, value, isDisabled = false }: CustomCheckboxProps) => {
  const handleChange = () => {
    onUpdate(!value)
  }

  return (
    <div className="customCheckbox-container">
      <Checkbox.Root className="customCheckbox-rot" checked={value} onCheckedChange={handleChange} disabled={isDisabled}>
        <Checkbox.Indicator>
          {value ? <AiOutlineCheck size="1.2em" /> : null}
        </Checkbox.Indicator>
      </Checkbox.Root>
      <label className="customCheckbox-label">
        {text}
      </label>
    </div>
  )
}

type CheckboxProps = {
  value: boolean
  onUpdate: Function
}
// Mediapreview : special behaviour
export const RoundCheckbox = ({ value, onUpdate }: CheckboxProps) => {
  const handleChange = () => {
    onUpdate(!value)
  }


  return (
    <Checkbox.Root className="customCheckbox-rot-round" checked={value} onCheckedChange={handleChange}>
      <Checkbox.Indicator style={{ margin: 'auto' }}>
        {value ? <IoCheckmarkCircleSharp size="1.2em" color="green" /> : null}
      </Checkbox.Indicator>
    </Checkbox.Root>
  )

}
export const SimpleCheckbox = ({ value, onUpdate }: CheckboxProps) => {
  const handleChange = () => {
    onUpdate(!value)
  }


  return (
    <Checkbox.Root className="customCheckbox-rot" checked={value} onCheckedChange={handleChange}>
      <Checkbox.Indicator>
        {value ? <AiOutlineCheck size="1.2em" /> : null}
      </Checkbox.Indicator>
    </Checkbox.Root>
  )

}