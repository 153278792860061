import { toast, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//toast.POSITION.TOP_CENTER
export const toastNotifyError = (message: string) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true
  });
};

export const toastNotifySuccess = (message: string, toastPosition = 'top-center') => {
  toast.success(message, {
    position: toastPosition as ToastPosition,
    hideProgressBar: true
  });
};
export const toastNotifyInfo = (message: string) => {
  toast.info(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true
  });
};