import '../../css/Profile.css';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { User, CountryItem, OrganizationType } from '../../types'
import { Api } from '../../api/api'
import { UserContributions } from './UserContributions';
import { ApplicationStorage } from '../../api/applicationStorage';
import { CustomCheckbox } from '../common/CustomCheckbox';
import { toastNotifyError } from '../common/ToastMessage';
import countryList from 'react-select-country-list'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'



const EditProfileComponent = () => {
  const userContext = useUserContext()
  const orgContext = useOrganizationContext()
  const history = useHistory()
  const countries = useMemo(() => countryList(), [])
  const [countiresData, setCountriesData] = useState<CountryItem[]>([])
  const [user, setUser] = useState<User>(userContext?.loggedInUser ? userContext?.loggedInUser : {} as User)
  const [form, setForm] = useState<User>()

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false)
  const [formBirthDate, setFormBirthDate] = useState("")
  const [edit, setEdit] = useState<boolean>(false)
  const [updateConfirm, setUpdateConfirm] = useState<boolean>(false)
  const [formTouched, setFormTouched] = useState<boolean>(false)
  const [organizationId, setOrganizationId] = useState<string>("")

  const { getTranslation } = useCustomTranslation(orgContext?.organization?.id || '')
  const submitButton = useRef<HTMLInputElement>(null);


  const onFormChange = (e: any) => {

    const { name, value } = e.target;

    let v = value;
    let prev = form ? form : {} as User
    setForm({
      ...prev,
      [name]: v
    });

    if (name === "dateOfBitrh") {
      setFormBirthDate(v);
    }
    setFormTouched(true)
  };

  const resetForm = () => {
    if (user && orgContext && orgContext.organization) {
      createForm(user, orgContext.organization)
    }
  }


  const createForm = useCallback((user: User, organization: OrganizationType,) => {
    let data = {} as User
    let keys = Object.keys(organization.registrationFields)
    for (const key of keys) {

      switch (key) {
        case 'place_of_birth':
          data['placeOfBirth'] = user['placeOfBirth'] as string
          break
        case 'street_address':
          data['streetAddress'] = user['streetAddress'] as string
          break

        case 'alias':
        case 'phone':
        case 'bio':
        case 'postalcode':
        case 'occupation':
        case 'gender':
          data[key] = user[key] as string
          break
        case 'country':
          data[key] = user.country
          break

        case 'date_of_birt':
          let userDate = user.dateOfBirth || ''
          data['dateOfBirth'] = new Date(userDate)
          break
      }
    }
    data['id'] = user.id
    data['firstName'] = user.firstName
    data['lastName'] = user.lastName
    data['email'] = user.email
    data['isPublic'] = user.isPublic ? user.isPublic : false
    data['roles'] = user.roles
    data['status'] = user.status
    setForm(data)

  }, [setForm])

  const onFormSubmit = (e: any) => {
    if (!form) return
    try {
      let f = form;
      f.dateOfBirth = new Date(formBirthDate);
      if (!f.isPublic && f.alias === '') {
        toastNotifyError(getTranslation('PROFILE.ALIAS_REQUIRED_ERROR'))
        return
      }
      if (f.alias && f.alias !== '') {
        f.isPublic = false
      }
      Api.updateUser(f).then((v) => {
        setUser(f);
        setUpdateConfirm(true);
        setFormTouched(false)
        setEdit(false)
        userContext?.setUser(f)
      })
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    let c: CountryItem[] = []
    let prioCountries: CountryItem[] = []
    let allCountries = countries.getData()
    allCountries.forEach((countryElement: CountryItem) => {
      switch (countryElement.value) {
        case 'DK':
        case 'FI':
        case 'NO':
        case 'SE':
          prioCountries.push(countryElement)
          break
        default:
          c.push(countryElement)
      }
    });
    setCountriesData([...prioCountries, ...c])
  }, [countries])

  useEffect(() => {
    let query = new URLSearchParams(window.location.search);
    let orgId = query.get("organization")
    if (orgId) {
      setOrganizationId(orgId)
    }


    let e = document.getElementById("confirmText");
    if (e) {
      e.addEventListener("webkitTransitionEnd", () => { setUpdateConfirm(false) });
    }

    if (userContext?.loggedInUser && orgContext && orgContext.organization) {
      if (userContext?.loggedInUser && userContext.loggedInUser.dateOfBirth) {
        let d = new Date(userContext.loggedInUser.dateOfBirth)
        setFormBirthDate(d.toISOString().split('T')[0]);
      }
      setUser(userContext.loggedInUser)
      createForm(user, orgContext.organization)
    } else {
      if (orgId) {
        history.push(`/organization/${orgId}`)
      } else {
        history.push('/')
      }
    }
  }, [userContext, history, createForm, orgContext, user])


  const inputFieldClasses = edit ? 'profile-input profile-edit-input' : 'profile-input profile-locked-input'

  const logout = () => {
    if (!userContext || !userContext.loggedInUser) return
    ApplicationStorage.removeToken()
    userContext.clearUserContext()
    if (orgContext && orgContext.organization) {
      history.push(`/organization/${orgContext.organization.id}`)
    } else {
      history.push('/')
    }
  }
  const renderProfile = () => {
    if (!user || !orgContext || !orgContext.organization) {
      return null
    }

    if (!user.dateOfBirth) {
      user.dateOfBirth = new Date()
    }
    let orgRegFields = orgContext.organization.registrationFields

    return (
      <div className="">
        <div className="profile-card">
          <div className="edit-profile-help-text">{getTranslation('PROFILE.EDIT_PROFILE_HELP')}</div>
          <div>
            {!edit && <button className="btn btn-default btn-default-big" onClick={() => { setEdit(true) }}>{getTranslation('GLOBAL.EDIT')}</button>}
          </div>
          <form className="profile-content" >

            <div className={"profile-field"} >
              <label className="profile-key">{`${getTranslation('REGISTRATION.FIRST_NAME')}*`}</label>
              <input name="firstName" onChange={(e) => { onFormChange(e); }} type="text" value={form ? form.firstName : ''} className={inputFieldClasses} disabled={!edit} />
            </div>

            <div className={"profile-field"} >
              <label className="profile-key">{`${getTranslation('REGISTRATION.LAST_NAME')}*`}</label>
              <input name="lastName" onChange={(e) => { onFormChange(e); }} type="text" value={form ? form.lastName : ''} className={inputFieldClasses} disabled={!edit} />
            </div>
            {'alias' in orgRegFields &&
              (<div className={"profile-field"} >
                <label className="profile-key">{`${getTranslation('REGISTRATION.ALIAS')}${orgRegFields['alias'] ? '*' : ''}`}</label>
                <input name="alias" onChange={(e) => { onFormChange(e); }} type="text" value={form ? form.alias : ''} className={inputFieldClasses} disabled={!edit} />
              </div>)}
            {'gender' in orgRegFields &&
              (<div className={"profile-field"} >
                <label className="profile-key">{`${getTranslation('REGISTRATION.GENDER')}${orgRegFields['gender'] ? '*' : ''}`}</label>
                <select name="gender" onChange={(e) => { onFormChange(e); }} value={form ? form.gender : ''} className={inputFieldClasses} id="gender" disabled={!edit}>
                  <option value={''} disabled></option>
                  <option value="Other">{getTranslation('REGISTRATION.GENDER_OTHER')}</option>
                  <option value="Female">{getTranslation('REGISTRATION.GENDER_FEMALE')}</option>
                  <option value="Male">{getTranslation('REGISTRATION.GENDER_MALE')}</option>
                </select>
              </div>)}
            {/* <div className={"profile-field"} >
              <label className="profile-key">{t('REGISTRATION.RECOVERY_EMAIL')}</label>
              <input name="recoveryEmail" onChange={(e) => { onFormChange(e); }} type="text" value={form.recoveryEmail} className={inputFieldClasses} disabled={!edit} />
            </div> */}
            {'phone' in orgRegFields &&
              (<div className={"profile-field"} >
                <label className="profile-key">{`${getTranslation('REGISTRATION.PHONE')}${orgRegFields['phone'] ? '*' : ''}`}</label>
                <input name="phone" onChange={(e) => { onFormChange(e); }} type="tel" minLength={6} value={form ? form.phone : ''} className={inputFieldClasses} disabled={!edit} />
              </div>)}
            {'date_of_birth' in orgRegFields &&
              (<div className={"profile-field"} >
                <label className="profile-key">{`${getTranslation('REGISTRATION.DATEOFBIRTH')}${orgRegFields['date_of_birth'] ? '*' : ''}`}</label>
                <input name="dateOfBitrh" onChange={(e) => { onFormChange(e); }} type="date" max="3000-01-01" min="1900-01-01" value={formBirthDate} className={inputFieldClasses} disabled={!edit} />
              </div>)}
            {'place_of_birth' in orgRegFields &&
              (<div className={"profile-field"} >
                <label className="profile-key">{`${getTranslation('REGISTRATION.PLACEOFBIRTH')}${orgRegFields['place_of_birth'] ? '*' : ''}`}</label>
                <input name="placeOfBirth" onChange={(e) => { onFormChange(e); }} type="text" value={form ? form.placeOfBirth : ''} className={inputFieldClasses} disabled={!edit} />
              </div>)}
            {'country' in orgRegFields &&
              (<div className={"profile-field"} >
                <label className="profile-key">{`${getTranslation('REGISTRATION.COUNTRY')}${orgRegFields['country'] ? '*' : ''}`}</label>
                <select
                  name="profile-country-select"
                  value={form ? form.country : ''}
                  onChange={(e: any) => {
                    let prev = form ? form : {} as User
                    setForm({ ...prev, country: e.target.value })
                  }}
                  className={inputFieldClasses}>
                  <option value={''} disabled>
                  </option>
                  {countiresData.map((s: any) => (
                    <option value={s.value}>
                      {s.label}
                    </option>
                  ))}
                </select>
              </div>)}
            {'street_address' in orgRegFields &&
              (<div className={"profile-field"} >
                <label className="profile-key">{`${getTranslation('REGISTRATION.STREET_ADDRESS')}${orgRegFields['street_address'] ? '*' : ''}`}</label>
                <input name="streetAddress" onChange={(e) => { onFormChange(e); }} type="text" value={form ? form.streetAddress : ''} className={inputFieldClasses} disabled={!edit} />
              </div>)}
            {'postalcode' in orgRegFields &&
              (<div className={"profile-field"} >
                <label className="profile-key">{`${getTranslation('REGISTRATION.POSTAL_CODE')}${orgRegFields['postalcode'] ? '*' : ''}`}</label>
                <input name="postalcode" onChange={(e) => { onFormChange(e); }} type="text" value={form ? form.postalcode : ''} className={inputFieldClasses} disabled={!edit} />
              </div>)}
            {'occupation' in orgRegFields &&
              (<div className={"profile-field"} >
                <label className="profile-key">{`${getTranslation('REGISTRATION.OCCUPATION')}${orgRegFields['occupation'] ? '*' : ''}`}</label>
                <input name="occupation" onChange={(e) => { onFormChange(e); }} type="text" value={form ? form.occupation : ''} className={inputFieldClasses} disabled={!edit} />
              </div>)}
            {'alias' in orgRegFields &&
              (<div className={"profile-field"} >
                <CustomCheckbox
                  text={getTranslation('PROFILE.PUBLIC')}
                  value={form ? form.isPublic : false}
                  onUpdate={(checked: boolean) => {
                    let prev = form ? form : {} as User
                    setForm({ ...prev, isPublic: checked })
                    setFormTouched(true)
                  }}
                  isDisabled={!edit}
                />
              </div>)}
            {'bio' in orgRegFields &&
              (<div className={"profile-field"} id="bio" >
                <label className="profile-key">{`Bio${orgRegFields['bio'] ? '*' : ''}`}</label>
                <textarea name="bio" onChange={(e) => { onFormChange(e); }} value={form ? form.bio : ''} className={inputFieldClasses + " bio-input"} disabled={!edit} />
              </div>)}

            {updateConfirm ? <p id="confirmText" className={"update-success-text-hidden"} > {getTranslation('PROFILE.UPDATE_NOTICE')}</p> : <p id="confirmText" className={"update-success-text"}></p>}
            <div>
              {edit && <button className="btn btn-default btn-default-big" onClick={(e) => { onFormSubmit(e); e.preventDefault(); }} disabled={!formTouched} > {getTranslation('GLOBAL.SAVE')}</button>}

              {edit ? <button className="btn btn-default btn-default-big" onClick={() => { setEdit(false); resetForm(); setUpdateConfirm(false) }}>{getTranslation('GLOBAL.CANCEL')}</button> : ""}
            </div>
            <input ref={submitButton} hidden={true} onClick={(e) => { e.preventDefault(); }} className="btn save-btn" type="submit" value="save" />
          </form>
        </div>
      </div >

    )
  }


  const misc = () => {
    return (
      <div >
        {/* <h3>{t('PROFILE.APP_SETTINGS.MISC')}</h3> */}
        <button className="btn btn-default btn-caution close-account-btn" onClick={() => { setShowDeleteModal(true) }}>{getTranslation('PROFILE.DELETE_ACCOUNT')}</button>
        <div className="modal" style={{ padding: "0px", display: showDeleteModal ? "block" : "none" }} >

          <div className="delete-modal">
            <button className="close-modal" aria-label="close" onClick={() => { setShowDeleteModal(false) }} >&times;</button>
            <div className="delete-modal-content" >
              <p>{getTranslation('PROFILE.DELETE_ACCOUNT_CONFIRM')}</p>
              <input type="text" className="confirm-delete-input" onChange={(e) => { e.target.value === userContext?.loggedInUser?.email ? setDeleteConfirm(true) : setDeleteConfirm(false) }} />
              <div>
                <button className="btn btn-default btn-caution" disabled={!deleteConfirm} onClick={() => { Api.deleteUser(); logout(); }} >{getTranslation('GLOBAL.DELETE')}</button>
                <button className="btn btn-default" onClick={() => { setShowDeleteModal(false) }}>{getTranslation('GLOBAL.CANCEL')}</button>
              </div>
            </div>
          </div>
        </div>
      </div >)
  }

  return (
    <div className="App">
      <div>
        <div className="app-content">
          <h1 className="profile-header">{getTranslation('PROFILE.PAGE_TITLE')}</h1>

          {renderProfile()}
          <UserContributions userId={user.id} organizationId={organizationId} />

          <button className="btn btn-default-with-border btn-default-big m-b-30" onClick={logout}>{getTranslation('LOGIN.LOGOUT')}</button>
          {misc()}
        </div>
      </div>
    </div>
  )
}


export const EditProfile = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="editProfile" router={history}>
        <EditProfileComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <EditProfileComponent {...props} />
  )
}


