import { ToolbarConfig } from 'react-rte';

export const REQUIRED_FIELDS = {
    TITLE: 'FIELD_TITLE',
    DESCRIPTION: 'FIELD_DESCRIPTION',
    DATE: 'FIELD_DATE',
    LOCATION: 'FIELD_LOCATION',
    // TAGS: 'FIELD_TAGS',
    IS_PHOTOGRAPHER: 'FIELD_IS_PHOTOGRAPHER'

}

export const socialsList = [
    { id: 'Facebook', name: "Facebook" },
    { id: "Instagram", name: "Instagram" },
    { id: "Snapchat", name: "Snapchat" },
    { id: "Twitter", name: "Twitter" }]


export const textEditorConfig: ToolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
    ]
};

export const simpleEditorConfig: ToolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
    ],
    BLOCK_TYPE_BUTTONS: []
};