import { createContext, useContext, useState, useEffect } from 'react'
import { User, KeyString } from '../types'
import { Api } from '../api/api'

type UserContextType = {
  loggedInUser: User | null
  userIsPlaformAdmin: boolean
  setUser: Function
  isFetchingUser: boolean
  clearUserContext: Function
  actionRoles: KeyString
}

const UserContext = createContext<UserContextType | undefined>(undefined)


const UserProvider = ({ token, roles, children }: any) => {
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null)
  const [isFetchingUser, setIsFetching] = useState<boolean>(true)
  const [userIsPlaformAdmin, setUserIsPlaformAdmin] = useState<boolean>(false)
  const [actionRoles] = useState<KeyString>(roles)

  const setUser = (u: User, isPlatformAdmin: boolean) => {
    setLoggedInUser(u)
    setUserIsPlaformAdmin(isPlatformAdmin)
    if (isFetchingUser) {
      setIsFetching(false)
    }
  }

  const clearUserContext = () => {
    setLoggedInUser(null)
  }

  useEffect(() => {
    const fetchUser = async (token: string) => {
      try {
        const response = await Api.getUser(token)
        setLoggedInUser({ ...response.user, roles: response.userRoles })
        setUserIsPlaformAdmin(response.isPlatformAdmin)
      } catch (error: any) {
        console.error("Could not get user due to: ", error)
      }
      finally {
        setIsFetching(false)
      }
    }
    if (token != null) {
      fetchUser(token)
    }
  }, [token])

  return (
    <UserContext.Provider
      value={{ loggedInUser, setUser, isFetchingUser, clearUserContext, actionRoles, userIsPlaformAdmin }} >
      {children}
    </UserContext.Provider >
  )
}

const useUserContext = (): UserContextType | undefined => useContext(UserContext)
export { UserProvider, useUserContext }