import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import '../../css/Profile.css';

import { useTranslation } from "react-i18next";
import { UserContributionType } from '../../types'
import { Api } from '../../api/api'
import { isVideo } from '../../helpers/mediaFileFunctions'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'

type Props = {
  userId: string
  organizationId: string
}

type CollectionContributions = {
  title: string
  contributions: UserContributionType[]
}
type SortedContributions = {
  [key: string]: CollectionContributions
}
type CollectionTitles = {
  [key: string]: string
}

export const UserContributions = ({ userId, organizationId }: Props) => {
  const { i18n } = useTranslation()
  const { getTranslation } = useCustomTranslation(organizationId)
  const history = useHistory()
  const [contributions, setContributions] = useState<UserContributionType[] | null>(null)


  useEffect(() => {
    const getContributions = async () => {
      try {
        const response = await Api.getUserContributions(userId, organizationId)

        setContributions(response)
      } catch (error: any) {
        console.error('error', error)
      }
    }
    if (userId) {
      getContributions()
    }
  }, [userId, organizationId])

  const navigateToContribution = (orgId: string, cId: string, contributionId: string) => {
    history.push({ pathname: `/organization/${orgId}/collections/${cId}/contribution/${contributionId}` })
  }

  const getCollectionTitleInLang = (titles: CollectionTitles, lang: string) => {
    if (lang in titles) {
      return titles[lang]
    }

    return titles['en']

  }




  const displayUserContributions = (contributions: UserContributionType[]) => {

    let contributionsByCollection: SortedContributions = {}

    contributions.forEach((c: UserContributionType) => {
      if (c.collectionId in contributionsByCollection) {
        contributionsByCollection[c.collectionId].contributions.push(c)
      } else {
        contributionsByCollection[c.collectionId] = {
          contributions: [c],
          title: getCollectionTitleInLang(c.collectionTitles, i18n.language)
        }
      }
    })

    let values = Object.values(contributionsByCollection)
    return (
      <>
        {
          values.map((c: CollectionContributions) => {
            return (
              <div>
                <h3>{c.title}</h3>
                <div className="user-contributions-list">
                  {c.contributions.map((contribution: UserContributionType) => {
                    if (contribution.collectionStatus === 'ARCHIVED') {
                      if (contribution.archivedText) {
                        return (
                          <div className="user-contribution-archived" dangerouslySetInnerHTML={{ __html: contribution.archivedText }} />
                        )
                      }
                    }
                    if (contribution.media.length === 0) return null

                    let media = contribution.media[0];
                    if (isVideo(media.originalFileName)) {
                      return (<video key={contribution.id} className="user-contribution" style={{ opacity: "0.4" }} onClick={() => navigateToContribution(contribution.organizationId, contribution.collectionId, contribution.id)} >
                        <source src={media.imgUrl} type={`video/${media.imgUrl.substr(media.imgUrl.lastIndexOf('.') + 1, media.imgUrl.length - 1)}`} />
                        Your browser does not support the video tag.
                      </video>)
                    }
                    return (
                      <img key={contribution.id} className="user-contribution" src={media.imgUrl} alt={media.title || "image"} onClick={() => navigateToContribution(contribution.organizationId, contribution.collectionId, contribution.id)} />
                    )
                  })}
                </div>
              </div>
            )
          })
        }
      </>
    )
  }

  if (!contributions) {
    return (<div className="loader--loading"></div>)
  }

  return (
    <>
      {contributions && contributions.length > 0 ? (
        <div >
          <div className="profile-card">
            <div className="profile-card-header">
              <h1 > {getTranslation('PROFILE.CONTRIBUTIONS')}</h1>
            </div>
            <div>
              {displayUserContributions(contributions)}
            </div>
          </div>
        </div >
      ) :
        null}
    </>
  );
}



