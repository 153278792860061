import { useState } from 'react'
import {
  ImageType,
  SimilarMedia
} from '../../types'

import { AiFillCamera } from 'react-icons/ai'
import { isVideo, isImage } from '../../helpers/mediaFileFunctions'


export type HoveOverMediaProps = {
  showHover: boolean
  photographer: string
}


type RendererProps = {
  mediaFile: ImageType | SimilarMedia
  fileId: string
  idKey: string
  classes?: string
  videoStyles?: React.CSSProperties
  onClickHandler?: Function
  showVideoControls?: boolean
  showInformationOnHover?: HoveOverMediaProps

}

export const MediaFileRenderer = ({ mediaFile, fileId, idKey = "", classes = "", videoStyles, onClickHandler, showVideoControls = false, showInformationOnHover = { showHover: false, photographer: '' } }: RendererProps) => {
  const [hoverClass, setHoverClass] = useState('mediaFileRenderer-hover-hide');
  const noop = () => { };
  const getAttributes = () => {
    let attributes: React.HTMLAttributes<any> = {}

    if (videoStyles && isVideo(mediaFile.originalFileName)) {
      attributes['style'] = videoStyles
    }
    return attributes
  }

  const getWrapperClasses = () => {
    if (showInformationOnHover.showHover) {
      return 'mediafileRenderer-hover-wrapper'
    }
    return ''
  }


  if (isImage(mediaFile.originalFileName)) {
    let imageUrl = mediaFile.thumbnailUrl ? mediaFile.thumbnailUrl : mediaFile.imgUrl
    return (
      <div
        key={idKey}
        className={getWrapperClasses()}
        onMouseEnter={e => {
          if (showInformationOnHover.showHover) {
            setHoverClass('mediaFileRenderer-hover-show');
          }
        }}
        onMouseLeave={e => {
          setHoverClass('mediaFileRenderer-hover-hide')
        }}>
        <img {...getAttributes()}
          src={imageUrl} alt={mediaFile.title || "image"}
          id={fileId}
          key={idKey}
          className={classes}
          onClick={() => onClickHandler ? onClickHandler() : noop()}
        />
        <div className={hoverClass}>
          <p>{mediaFile.title ? mediaFile.title : '-'}</p>
          <div className="mediafile-icon-text-wrapper">
            <div className="mediafile-photographer-icon-wrapper">
              <div style={{ marginTop: '1px' }}>
                <AiFillCamera color="black" />
              </div>
            </div>
            <p style={{ marginLeft: '4px' }}>{showInformationOnHover.photographer}</p>
          </div>
        </div>

      </div>)
  }

  return (
    <div
      key={idKey}
      className={getWrapperClasses()}
      onMouseEnter={e => {
        if (showInformationOnHover.showHover) {
          setHoverClass('mediaFileRenderer-hover-show');
        }
      }}
      onMouseLeave={e => {
        setHoverClass('mediaFileRenderer-hover-hide')
      }}>
      <video {...getAttributes()}
        id={fileId}
        key={idKey}
        className={classes}
        onClick={() => onClickHandler ? onClickHandler() : noop()}
        controls={showVideoControls}
        autoPlay={showVideoControls}
        muted={showVideoControls} >
        <source
          src={mediaFile.imgUrl}
          type={`video/${mediaFile.imgUrl.substr(mediaFile.imgUrl.lastIndexOf('.') + 1, mediaFile.imgUrl.length - 1)}`} />
        Your browser does not support the video tag.
      </video>
      <div className={hoverClass}>
        <p>{mediaFile.title ? mediaFile.title : '-'}</p>
        <div className="mediafile-icon-text-wrapper">
          <div className="mediafile-photographer-icon-wrapper">
            <div style={{ marginTop: '1px' }}>
              <AiFillCamera color="black" />
            </div>
          </div>
          <p style={{ marginLeft: '4px' }}>{showInformationOnHover.photographer}</p>
        </div>
      </div>
    </div>
  )

}