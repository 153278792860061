import {
  MediaListItem,
  EditFileType,
  MediaType,
  KeyBoolean,
  ContributionFormType,
  ImageType,

} from '../types'


export const isVideo = (filename: string): boolean => {
  let fileUrl = filename.toLowerCase()
  return (fileUrl.endsWith('.mp4') || fileUrl.endsWith('.mov') || fileUrl.endsWith('.mpeg') || fileUrl.endsWith('.mpg'))
}

export const isImage = (filename: string): boolean => {
  let fileUrl = filename.toLowerCase()
  return (fileUrl.endsWith('.png') || fileUrl.endsWith('gif') || fileUrl.endsWith('.jpeg') || fileUrl.endsWith('.jpg') || fileUrl.endsWith('.tiff') || fileUrl.endsWith('.tif') || fileUrl.endsWith('.dng') || fileUrl.endsWith('.jfif'))
}

export const getFileExtension = (file: MediaListItem | EditFileType) => {
  if ('formValues' in file) {
    return (file as MediaListItem).mediaFile.File.type
  } else {
    let fileUrl = (file as EditFileType).mediaFile.imgUrl.toLowerCase()
    if (fileUrl.endsWith('.png') || fileUrl.endsWith('gif') || fileUrl.endsWith('.jpeg') || fileUrl.endsWith('.jpg')) {
      return ""
    } else {
      return `video/${fileUrl.substr(fileUrl.lastIndexOf('.') + 1, fileUrl.length - 1)}`
    }
  }
}


export const getType = (file: MediaListItem | EditFileType) => {
  if ('formValues' in file) {
    return (file as MediaListItem).mediaFile.type
  } else {
    let fileUrl = (file as EditFileType).mediaFile.imgUrl.toLowerCase()
    if (fileUrl.endsWith('.png') || fileUrl.endsWith('gif') || fileUrl.endsWith('.jpeg') || fileUrl.endsWith('.jpg') || fileUrl.endsWith('.tiff') || fileUrl.endsWith('.tif') || fileUrl.endsWith('.dng') || fileUrl.endsWith('.jfif')) {
      return MediaType.Image
    } else {
      return MediaType.Video
    }
  }
}

export const getInitialMediaFormValuesForm = (contributionMediaFields: KeyBoolean) => {
  let initalForm: { [Property in keyof ContributionFormType]: string | string[] | boolean | number | null } = {}
  let fields = Object.keys(contributionMediaFields)
  for (const key of fields) {
    switch (key) {
      case 'title':
        initalForm['title'] = ''
        break
      case 'description':
        initalForm['description'] = ''
        break
      case 'date':
        initalForm['date'] = ''
        break
      case 'openDate':
        initalForm['openDate'] = ''
        break
      case 'tags':
        initalForm['tags'] = []
        break
      case 'socials':
        initalForm['socials'] = []
        break
      case 'location':
        initalForm['location'] = ''
        initalForm['locationLng'] = null
        initalForm['locationLat'] = null
        break
      case 'isPhotographer':
        initalForm['isPhotographer'] = true
        initalForm['otherPhotographer'] = ''
        break
      case 'license':
        initalForm['license'] = ''
        break

    }
  }

  return initalForm
}

export const getDefaultPropertyValue = (field: keyof ContributionFormType) => {
  let value
  switch (field) {
    case 'title':
    case 'description':
    case 'date':
    case 'location':
    case 'license':
    case 'otherPhotographer':
    case 'openDate':
      value = ''
      break
    case 'isPhotographer':
      value = true
      break
    case 'tags':
    case 'socials':
      value = [] as string[]
      break
    case 'locationLng':
    case 'locationLat':
      value = null
      break
  }

  return value
}
type FormValueType = string | number | boolean | string[] | null

const convertDateToIsoString = (dateString: Date): string => {
  let n = new Date(dateString.toString()).toISOString()
  let formatted = n.substr(0, n.indexOf('T'))
  return formatted
}


export const getFilledValueEditFileType = (field: keyof ImageType, file: EditFileType): FormValueType => {
  let value: any = null
  switch (field) {
    case 'date':
      if (file.mediaFile['date'] as Date) {
        value = convertDateToIsoString(file.mediaFile[field] as Date)
      }
      break
    case 'otherPhotographer':
      if (!file.mediaFile[field]) {
        value = ''
      }
      break
    case 'isPhotographer':
      if (!file.mediaFile[field]) {
        value = false
      }
      break
    default:
      if (file.mediaFile[field]) {
        value = file.mediaFile[field]
      }
  }

  if (!value) {
    return getDefaultPropertyValue(field as keyof ContributionFormType)
  }
  return value
}



