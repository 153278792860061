
export const ApplicationStorage = (() => {
  const setToken = (token: string) => {
    localStorage.setItem('token', token);
  }

  const getToken = () => {
    return localStorage.getItem('token')
  }

  const getLanguage = () => {
    return localStorage.getItem('i18nextLng')
  }

  const setUserLanguageSelection = (lang: string) => {
    localStorage.setItem('userLangSelection', lang);
  }
  const getUserLanguageSelection = () => {
    return localStorage.getItem('userLangSelection');
  }

  const removeToken = () => {
    localStorage.removeItem('token')
  }

  return Object.freeze({ setToken, getToken, getLanguage, removeToken, setUserLanguageSelection, getUserLanguageSelection })

})()