import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { VscCopy } from 'react-icons/vsc'
import { IoChevronForward, IoChevronBack } from 'react-icons/io5'
import { AdminApi } from '../../api/admin'
import { OrganizationContribution, ImageType, CollectionsSummary } from '../../types'
import { useOrganizationContext } from '../../context/OrganizationContext'
import { Table, Cell, Row } from '../common/TableComponents'
import { formatDateString } from '../../helpers/helperFunctions'
import { toastNotifySuccess } from '../common/ToastMessage'
import { MediaFileRenderer } from '../common/MediaFileRenderer'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'

type RecentPostsProps = {
  show: boolean
  organizationColor: string
  organizationId: string
}
export const RecentPosts = ({ show, organizationColor, organizationId }: RecentPostsProps) => {

  const history = useHistory()
  const orgCtx = useOrganizationContext()
  const [contributions, setContributions] = useState<OrganizationContribution[] | null>(null)
  const [contributionsSummary, setContriutionsSummary] = useState<CollectionsSummary | null>(null)
  const [fromCount, setFromCount] = useState<number>(0)
  const [toCount, setToCount] = useState<number>(0)

  const { getTranslation } = useCustomTranslation(organizationId)

  const paginate = async (forward: boolean) => {
    if ((!fromCount && !forward) || (forward && toCount === contributionsSummary?.totalContributionCount)) {
      return
    }
    let orgId = orgCtx?.organization?.id
    let newBatch: OrganizationContribution[] = []
    let paginationId: number = 0
    if (!contributions || !orgId) return
    if (forward) {
      paginationId = contributions[contributions.length - 1].paginationId

    } else {
      paginationId = contributions[0].paginationId
    }
    let direction = forward ? 'forward' : 'backward'
    newBatch = await AdminApi.getOrganizationContributions(orgId, paginationId, direction)
    let newFrom = forward ? toCount : fromCount - newBatch.length
    setToCount(newFrom + newBatch.length)
    setFromCount(newFrom)
    setContributions(newBatch)
  }


  const getOrganizationContributions = async () => {
    if (orgCtx && orgCtx.organization) {
      const response = await AdminApi.getOrganizationContributions(orgCtx.organization.id, 0, 'forward')
      setContributions(response)
      setToCount(response.length)
    }
  }

  const getOrganizationCollectionSummaries = async () => {
    if (!orgCtx || !orgCtx.organization) return
    const response = await AdminApi.getOrganizationContributionsSummary(orgCtx.organization.id)
    setContriutionsSummary(response)

  }

  const renderPaginationInformation = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div>{fromCount + 1}</div>
        -
        <div>{toCount}</div>
        <div style={{ marginLeft: '5px' }}> of {contributionsSummary?.totalContributionCount}</div>
      </div>
    )
  }

  const renderMediaCell = (images: ImageType[]) => {
    if (images.length === 0) {
      return
    }
    return (
      <div className="admin-recent-post-preview-media">
        <MediaFileRenderer
          mediaFile={images[0]}
          fileId={images[0].id}
          idKey={images[0].id}
          videoStyles={{ width: 'inherit', height: 'inherit' }}
          classes={images.length === 1 ? 'admin-recent-post-media' : 'admin-recent-post-media admin-recent-post-media-multiple'}
        />
      </div>
    )
  }

  if (!show) {
    return null
  }

  if (show && !contributionsSummary) {
    getOrganizationCollectionSummaries()
  }

  if (show && !contributions) {
    getOrganizationContributions()
    return (
      <div className="loader--loading" style={{ height: '100vh' }}></div>
    )
  }

  return (
    <div className="admin-recent-posts-section">
      <h3>{getTranslation('ADMIN.RECENT_POSTS.TITLE')}</h3>
      <Table>
        <Row>
          <Cell>{getTranslation('ADMIN.RECENT_POSTS.TABLE_COLUMNS.IMAGES')}</Cell>
          <Cell>{getTranslation('ADMIN.RECENT_POSTS.TABLE_COLUMNS.USER')}</Cell>
          <Cell>{getTranslation('ADMIN.RECENT_POSTS.TABLE_COLUMNS.PUBLISHED')}</Cell>
          <Cell>{getTranslation('ADMIN.RECENT_POSTS.TABLE_COLUMNS.COLLECTION')}</Cell>
          <Cell>{getTranslation('ADMIN.RECENT_POSTS.TABLE_COLUMNS.ACTIONS')}</Cell>
        </Row>
        {contributions?.map(c => {
          return (
            <Row>
              <Cell>
                {renderMediaCell(c.media)}
              </Cell>
              <Cell>
                <div onClick={() => {
                  navigator.clipboard.writeText(c.user.email)
                  toastNotifySuccess(`${c.user.email} copied to clipboard `)
                }} className="admin-cell-name">
                  <div>{c.user.name}</div>
                  <div>
                    {c.user.email}
                    <VscCopy />
                  </div>
                </div>
              </Cell>
              <Cell>{formatDateString(c.publishedAt)}</Cell>
              <Cell>{c.collectionTitle}</Cell>
              <Cell>
                <button
                  className="btn"
                  style={{ backgroundColor: organizationColor, padding: '10px 25px' }}
                  onClick={() => {
                    history.push(`/organization/${organizationId}/authenticated/${c.collectionId}/contribution/${c.id}/edit-contribution`)
                  }}>
                  {getTranslation('ADMIN.RECENT_POSTS.TABLE_ACTION_EDIT')}
                </button>
                <button
                  className="btn"
                  style={{ backgroundColor: organizationColor, padding: '10px 25px' }}
                  onClick={() => {
                    history.push({ pathname: `/organization/${organizationId}/collections/${c.collectionId}/contribution/${c.id}` })
                  }}>
                  {getTranslation('ADMIN.RECENT_POSTS.TABLE_ACTION_GO_TO')}
                </button>
              </Cell>
            </Row>
          )
        })}
      </Table>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IoChevronBack size="1.5em" color={fromCount === 0 ? 'grey' : 'black'} onClick={() => paginate(false)} />
        {renderPaginationInformation()}
        <IoChevronForward size="1.5em" color={toCount === contributionsSummary?.totalContributionCount ? 'grey' : 'black'} onClick={() => paginate(true)} />
      </div>
    </div >
  )
}