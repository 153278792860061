import { useState } from "react"
import { AiOutlineFilePdf, AiOutlineFileWord } from 'react-icons/ai'
import { RelatedFilesProps, Event } from '../../../types'
import { TextArea } from "./FormComponents"

export const RelatedFiles = ({ title, commentTitle, onUpdate, onCommentUpdate, handleKeyDown, value, commentValue, subText }: RelatedFilesProps) => {
  const [show, setShow] = useState<boolean>(false)

  const showSelectedFiles = () => {
    const files = () => {
      if (!value) {
        return null
      }
      let filesArray: Array<any> = []
      if (value) {
        for (const f in value) {
          if (typeof value[f] === 'object') {
            let type = value[f].type === 'application/pdf' ? 'pdf' : 'other'
            filesArray.push({ name: value[f].name, type })
          }
        }
      }

      return (
        <>
          <div style={{ marginTop: '20px' }}>
            {
              filesArray.map((file: any) => (
                <div className="flex">
                  {file.type === 'pdf' ? (<AiOutlineFilePdf />) : (<AiOutlineFileWord />)}
                  <span className="file-name">{file.name}</span>
                </div>
              ))
            }
            <br />
            <TextArea
              title={commentTitle}
              name="filesComment"
              placeholder=""
              onUpdate={(name: string, value: string) => onCommentUpdate(value)}
              handleKeyDown={handleKeyDown}
              value={commentValue} />
          </div>
        </>
      )
    }

    return (
      <div>
        {files()}
      </div>
    )
  }

  // const showClasses = show ? 'pc1' : 'pc1 hide'

  return (
    <div className="m-t-30">
      <div className="flex" onClick={() => setShow(!show)}>
        <label style={{ fontSize: 'medium' }} htmlFor="relatedFiles">{title}</label>
        {/* {show ? <GrFormSubtract size="1.5rem" /> : <GrFormAdd size="1.5rem" />} */}
      </div>
      <div className="related-files-sub">
        <p>{subText}</p>
      </div>
      <div style={{ display: 'grid' }}>
        <label className="relatedfiles-input">
          <input type="file" id="relatedFiles" name="relatedFiles" multiple accept=".doc,.docx, .pdf, audio/*" onChange={(event: Event<HTMLInputElement>) => onUpdate(event)} onKeyDown={(e) => handleKeyDown(e)} />
        </label>
        {showSelectedFiles()}
      </div>

    </div>
  )
}