import { ContributionFormType } from './ContributionTypes'

export type ImageType = {
  id: string
  contributionId: string
  imgUrl: string
  thumbnailUrl: string | null
  title: string | null
  description: string | null
  date: Date | null
  location: string | null
  locationLat: number | null
  locationLng: number | null
  socials: Array<string> | null
  tags: Array<string> | null
  isPhotographer: boolean | null
  license: string | null
  metaData: string | null
  originalFileName: string
  otherPhotographer: string | null
  openDate: string | null
  paginationId: number
}


export type CollectionMediaType = ImageType & { contributionUserAlias: string, contributionUserFirstName: string, contributionUserLastName: string }

export type MediaFile = {
  URL: string
  File: File
  type: MediaType
}
export type FilesUpLoadState = {
  selectedFiles: FileList | null
}

export type SingleFileUpLoadState = {
  selectedFile: File | null
}


export type MediaListItem = {
  mediaFile: MediaFile
  formValues: ContributionFormType | null
}

export type EditFileType = {
  mediaFile: ImageType
  type: string
}


export type FormImageType = {
  [id: string]: MediaListItem | EditFileType
}

export type FileType = {
  id: string
  contributionId: string
  originalFileName: string
  fileUrl: string
  comment: string
  metaData: string
}

export enum MediaType {
  Image = "IMAGE",
  Video = "VIDEO",
}


export type UpdateMediaType = {
  id: string
  contributionId: string
  title: string
  description: string
  date: Date
  location: string
  locationLat: number
  locationLng: number
  socials: string[]
  tags: string[]
  isPhotographer: boolean
  license: string
  otherPhotographer: string | null
  openDate: string
}

export type UpdateFilesType = {
  id: string
  contribution_id: string
  comment: string
}

export type SelectedItem = {
  mediaItem: MediaListItem | EditFileType
  itemIndx: number
}