import { useState, useEffect } from 'react'
import { useLocation, Link, useHistory, useParams } from 'react-router-dom'
import { useOrganizationContext } from '../../context/OrganizationContext';
import { Api } from '../../api/api'
import { ROUTE } from '../../routes'
import ErrorBoundary from '../../errorHandlers/ErrorBoundry'
import { useCustomTranslation } from '../../helpers/CustomTranslationsComps'

const PasswordResetComponent = () => {

  const orgCtx = useOrganizationContext()
  const history = useHistory()
  const location = useLocation()
  const search = new URLSearchParams(useLocation().search);

  const queryUser = search.get('user')
  const queryToken = search.get('token')

  const { orgId } = useParams<{ orgId: string }>();
  const { getTranslation } = useCustomTranslation(orgId)

  const [submitted, setSubmitted] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [email, setEmail] = useState(queryUser ? queryUser : "")
  const [token, setToken] = useState(queryToken ? queryToken : "")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [apiReponseCode, setApiReponseCode] = useState(0)
  const [redirectOrg, setRedirectOrg] = useState<any>({
    organizationId: '',
    route: ''
  })


  const sendNewPassword = async (): Promise<any> => {
    if (newPassword !== confirmPassword) {
      return
    }

    try {
      await Api.confirmPasswordReset({
        "email": email,
        "reset_token": token,
        "password": newPassword
      })

      setPasswordChanged(true)
    } catch (error: any) {
      setApiReponseCode(error.status)
    }
  }


  const errorMessage = () => {
    if (apiReponseCode === 412) {
      return (<div>
        <p className="login-error">{getTranslation('LOGIN.RESET_TOKEN_INVALID')}</p>
      </div>)
    }

    if (apiReponseCode === 400 && newPassword.length < 6) {
      return (<div>
        <p className="login-error">{getTranslation('LOGIN.PASSWORD_TOO_SHORT')}</p>
      </div>)
    }

    if (submitted && newPassword !== confirmPassword) {
      return (<div>
        <p className="login-error">{getTranslation('LOGIN.PASSWORD_MISMATCH')}</p>
      </div>)
    }


    return (<div><p>&nbsp;</p></div>)
  }

  useEffect(() => {
    if (location.state) {
      const { redirectOrg }: any = location.state
      setRedirectOrg({ orgId: redirectOrg, route: `/organization/${redirectOrg}` })
    }
    else if (!queryUser || !queryToken) {
      history.push(ROUTE.HOME)
    } else {
      setRedirectOrg({ organizationId: orgId, route: `/organization/${orgId}` })
    }
  }, [location, history])

  const emailForm = () => {
    return (
      <div>
        <h1>{getTranslation('LOGIN.RESET_PASSWORD')}</h1>
        <div className="password-reset-request">
          {!submitted ?
            <div>
              <div className="password-reset-field">
                <label>{getTranslation('REGISTRATION.EMAIL')}</label>
                <input type="text" placeholder={`${getTranslation('REGISTRATION.EMAIL')}`} name="email" onChange={(e: any) => { setEmail(e.target.value); }} value={email} />
              </div>
              <button className="btn btn-default classhName= password-reset-email" onClick={() => {
                if (!orgCtx || !orgCtx.organization) {
                  history.push(ROUTE.HOME)
                }
                Api.requestPasswordReset(email, orgCtx!.organization!.id);
                setSubmitted(true);
              }}>{getTranslation('LOGIN.RESET_PASSWORD_SUBMIT')}</button>
            </div>
            : <div>
              <p>{getTranslation('LOGIN.PASSWORD_EMAIL_NOTICE')}</p>
              <Link to={{ pathname: `/login`, state: { redirectOrg: redirectOrg.organizationId } }} >{getTranslation('LOGIN.LOGIN_LINK')}</Link>
            </div>}
        </div >
      </div>
    )
  }


  const newPasswordForm = () => {
    return (
      <div>
        <h1>{getTranslation('LOGIN.RESET_PASSWORD')}</h1>
        <div className="password-reset-confirm">
          {!passwordChanged ?
            <div>
              <div className="password-reset-field">
                <label>{getTranslation('REGISTRATION.EMAIL')}</label>
                <input type="text" placeholder={`${getTranslation('REGISTRATION.EMAIL')}`} name="email" onChange={(e: any) => { setEmail(e.target.value); }} value={email} disabled />
              </div>

              <div className="password-reset-field">
                <label>{getTranslation('LOGIN.RESET_TOKEN')}</label>
                <input type="text" placeholder={getTranslation('LOGIN.RESET_TOKEN')} name="token" onChange={(e: any) => { setToken(e.target.value); }} value={token} disabled />
              </div>

              <div className="password-reset-field">
                <label>{getTranslation('LOGIN.NEW_PASSWORD')}</label>
                <input type="password" placeholder={getTranslation('LOGIN.NEW_PASSWORD')} name="password" onChange={(e: any) => { setNewPassword(e.target.value); }} value={newPassword} />
              </div>

              <div className="password-reset-field">
                <label>{getTranslation('LOGIN.CONFIRM_PASSWORD')}</label>
                <input type="password" placeholder={getTranslation('LOGIN.CONFIRM_PASSWORD')} name="confirm password" onChange={(e: any) => { setConfirmPassword(e.target.value); }} value={confirmPassword} />
              </div>

              {errorMessage()}

              <button className="btn btn-default" onClick={() => { sendNewPassword(); setSubmitted(true); }} >{getTranslation('GLOBAL.SAVE')}</button>
            </div>
            : <div>
              <p>{getTranslation('LOGIN.PASSWORD_RESET_SUCCESS')}</p>
              <Link to={{ pathname: `/login`, state: { redirectOrg: redirectOrg.organizationId } }} >{getTranslation('LOGIN.LOGIN_LINK')}</Link>
            </div>
          }
        </div >
      </div >
    )
  }

  return (
    <div className="App">
      <div className="login-container">
        <div className="login-content">
          <div className="password-reset">
            {token === "" ? emailForm() : newPasswordForm()}
          </div>
        </div>
      </div>
    </div >
  );
}


export const PasswordReset = ({ ...props }) => {
  const history = useHistory()
  if (props.environment === "prod") {
    return (
      <ErrorBoundary location="PwReset" router={history}>
        <PasswordResetComponent {...props} />
      </ErrorBoundary>
    )
  }
  return (
    <PasswordResetComponent {...props} />
  )
}